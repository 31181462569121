import React from 'react';
import styled from '@emotion/styled';
import { IUrlLinkItem, Link, Responsive } from '@m2/blueprint-ui';
import { Color } from '../../../styles/Color';
import { getLinkPropsForUrl } from '../../../navigation';

interface IStyledFooterLinkListProps {
	isOpen: boolean;
}

export const StyledFooterLinkList = styled.ul<IStyledFooterLinkListProps>`
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		margin-bottom: 10px;
		padding-left: 0;

		a {
			font-size: 16px;
			color: ${Color.PrimaryDarkGray100};
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		${Link} {
			text-decoration: none;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		${({ isOpen }) => !isOpen && 'display: none;'}
		padding-top: 12px;
		font-size: 16px;

		a {
			font-size: 16px;
			color: ${Color.PrimaryDarkGray100};
		}
	}
`;

export interface IFooterLinkListProps {
	isOpen: boolean;
	links: IUrlLinkItem[];
}

export const FooterLinkList = ({ links, isOpen }: IFooterLinkListProps) => (
	<StyledFooterLinkList isOpen={isOpen}>
		{links?.map((link) => (
			<StyledListItem key={Math.random()}>
				<Link {...getLinkPropsForUrl(link.url)}>{link.title}</Link>
			</StyledListItem>
		))}
	</StyledFooterLinkList>
);

export const StyledListItem = styled.li`
	list-style-type: none;
`;
