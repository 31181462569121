import styled from '@emotion/styled';
import { themed, Responsive, ITheme } from '@m2/blueprint-ui';

export const FooterSectionHeader = styled.h4<{}, ITheme>`
	color: ${themed(({ color }) => color.text.inverted)};
	margin-bottom: 24px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: 16px;
	}
`;
