import React from 'react';
import styled from '@emotion/styled';
import {
	FixedGridItem,
	Link,
	Responsive,
	FacebookIcon,
	InstagramIcon,
	TwitterIcon,
	YouTubeIcon,
	ITheme,
} from '@m2/blueprint-ui';

import LinkedInIcon from '../../../assets/icons/linkedin-icon.svg?reference';
import PinterestIcon from '../../../assets/icons/pinterest-icon.svg?reference';

import { FooterSectionHeader } from './FooterSectionHeader';

const FooterSocialLinksWrap = styled.div<{}, ITheme>`
	display: flex;

	a {
		color: #fff;
		margin-right: 28px;

		&:last-of-type {
			margin-right: 0;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		a {
			margin-right: 16px;
		}

		svg {
			width: 20px;
			height: auto;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		justify-content: flex-start;
	}
`;

const StyledIconWrapper = styled.div<{}, ITheme>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 8px;
	background-color: #000000;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		width: 40px;
		height: 40px;
	}
`;

export enum ISocialLinkItemPlatform {
	Facebook = 'Facebook',
	Instagram = 'Instagram',
	Twitter = 'Twitter',
	YouTube = 'YouTube',
	LinkedIn = 'LinkedIn',
	Pinterest = 'Pinterest',
}

export interface ISocialLinkItem {
	url?: string;
	platform: ISocialLinkItemPlatform;
}

export interface IFooterSocialLinksProps {
	title: string;
	links: ISocialLinkItem[];
}

export const FooterSocialLinks = ({
	title,
	links,
}: IFooterSocialLinksProps) => (
	<FixedGridItem>
		<FooterSectionHeader>{title}</FooterSectionHeader>
		<FooterSocialLinksWrap>{links.map(renderLink)}</FooterSocialLinksWrap>
	</FixedGridItem>
);

function renderLink(link: ISocialLinkItem) {
	return (
		<Link
			hideExternalIndicator
			key={link.platform}
			href={link?.url}
			title={link.platform}
			target="_blank"
			rel="noopener"
		>
			<StyledIconWrapper>{getIconForPlatform(link.platform)}</StyledIconWrapper>
		</Link>
	);
}

function getIconForPlatform(platform: ISocialLinkItemPlatform) {
	switch (platform) {
		case 'Facebook':
			return <FacebookIcon variation="inverted" />;

		case 'Instagram':
			return <InstagramIcon variation="inverted" />;

		case 'Twitter':
			return <TwitterIcon variation="inverted" />;

		case 'YouTube':
			return <YouTubeIcon variation="inverted" />;

		case 'Pinterest':
			return <img src={PinterestIcon} alt="" />;

		case 'LinkedIn':
			return <img src={LinkedInIcon} alt="" />;

		default:
			throw new Error(`No icon available for platform: ${platform}`);
	}
}
