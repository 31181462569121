import { IPathToPageIdMap } from '@m2/blueprint-contentful-routes';
import { CustomPageId } from './CustomPageId';

export const customPagePathToPageIdMap: IPathToPageIdMap = Object.freeze({
	'/flights/': CustomPageId.Flights,
	'/flight/': CustomPageId.Flight,
	'/shop-dine-services/': CustomPageId.ShopDineRelax,
	'/map/': CustomPageId.Map,
	'/security/': CustomPageId.Security,
	'/park/': CustomPageId.Park,
	'/shuttles/': CustomPageId.Shuttles,
	'/shuttle-stop': CustomPageId.ShuttleStop,
	'/': CustomPageId.Home,
});
