import { UnifiedSearchStore } from '../domain/stores/createUnifiedSearchStore';

export type UnifiedSearchMenuToggleViewModel = ReturnType<
	typeof createUnifiedSearchMenuToggleViewModel
>;

export interface IUnifiedSearchMenuToggleViewModelProps {
	isTransparent: boolean;
	unifiedSearchStore: UnifiedSearchStore;
}

export const createUnifiedSearchMenuToggleViewModel = ({
	isTransparent,
	unifiedSearchStore,
}: IUnifiedSearchMenuToggleViewModelProps) => ({
	isTransparent,
	get isUnifiedSearchActive() {
		return unifiedSearchStore?.isActive;
	},
});
