/* eslint-disable no-underscore-dangle */
import React, { HTMLAttributes, useEffect } from 'react';

const SCRIPT_URL = '//seal.digicert.com/seals/cascade/seal.min.js';

export const DigicertSiteSeal = ({
	...divProps
}: HTMLAttributes<HTMLDivElement>) => {
	useEffect(() => {
		const digicertSitesealScript = document.createElement('script');
		digicertSitesealScript.defer = true;
		digicertSitesealScript.src = SCRIPT_URL;

		window.__dcid = window.__dcid || [];
		window.__dcid.push({
			cid: 'DigiCertClickID_zwJTT4T_',
			tag: 'zwJTT4T_',
			seal_format: 'dynamic',
		});

		document.body.appendChild(digicertSitesealScript);

		return () => {
			document.body.removeChild(digicertSitesealScript);
		};
	}, []);

	return <div id="DigiCertClickID_zwJTT4T_" {...divProps} />;
};

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		__dcid: { cid: string; tag: string; seal_format: string }[];
	}
}
