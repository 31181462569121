import { AxiosRequestConfig } from 'axios';
import { AbstractService } from './AbstractService';
import { ISkydiveWaitTimesResponse } from '../types/ISkydiveWaitTimesResponse';

export interface IWaitTimesParams {
	type: string;
	airport?: 'DFW' | string;
}
export class WaitTimesService extends AbstractService {
	/**
	 * Path of the resource to be used by the service.
	 */
	public static readonly URI_PATH = `/wait-times`;

	/**
	 * API version necessary for the service.
	 */
	public static readonly VERSION = 170;

	/**
	 * Request wait times information.
	 * @param params Get params
	 * @param requestConfig Config options for axios
	 */
	public get(
		params: IWaitTimesParams,
		requestConfig: AxiosRequestConfig = {
			method: 'GET',
			headers: {
				'Api-Version': WaitTimesService.VERSION,
			},
		},
	): Promise<ISkydiveWaitTimesResponse> {
		const uri = `${WaitTimesService.URI_PATH}/${
			params.type ?? 'checkpoint'
		}/DFW`;

		return this.client.request(uri, { ...requestConfig });
	}
}
