import {
	observable,
	computed,
	action,
	IObservableArray,
	makeObservable,
} from 'mobx';
import { IAirport } from '../types';

export class AirportStore<AirportType = IAirport> {
	protected _airports: IObservableArray<AirportType> = observable<AirportType>(
		[],
	);

	constructor() {
		makeObservable(this, {
			airports: computed,
			setAirports: action,
		});
	}

	public get airports() {
		return this._airports;
	}

	public setAirports(airports: AirportType[]) {
		this._airports.replace(airports.map((airport) => airport));
	}
}
