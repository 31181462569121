import React from 'react';

import styled from '@emotion/styled';
import Link from 'next/link';
import { Responsive } from '@m2/blueprint-ui';
import IconError from '../../../assets/error-icon.svg?reference';
import { Color } from '../../../styles/Color';
import { Footer } from '../footer';
import { TopNavigation } from '../header/components';
import { useTranslation } from '../../../i18n/translate/useTranslation';

const ErrorPage = () => {
	const { t } = useTranslation();

	return (
		<>
			<TopNavigation />
			<CenterWrapper>
				<img src={IconError} alt="" />
				<Link href="/">
					<StyledButton>{t('error_page_back_button_text')}</StyledButton>
				</Link>
			</CenterWrapper>
			<Footer />
		</>
	);
};

export default ErrorPage;

export const CenterWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 200px;

	img {
		margin-bottom: 40px;

		@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
			width: 50vw;
			height: auto;
		}
	}
`;

export const StyledButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
	font-size: 24px;
	font-weight: bold;
	color: white;
	line-height: normal;
	font-size: 16px;
	height: 48px;
	padding: 11px 24px;
	border-radius: 4px;
	margin-bottom: 80px;
	background-color: ${Color.PrimaryOrange100};

	@media (${Responsive.getMediaQueryForBreakpoint('desktopXL')}) {
		margin-bottom: 200px;
	}
`;
