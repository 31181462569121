import i18n from 'i18next';
import log from 'loglevel';
import { Translations } from './Translations';

const TRANSLATIONS_NAMESPACE = 'translation';

const cache: Record<string, typeof i18n> = {};

export function initI18nWithTranslations(
	locale: string,
	translations: Translations,
) {
	if (locale in cache) {
		return cache[locale];
	}

	const instance = i18n.createInstance();

	instance.init({
		ns: [TRANSLATIONS_NAMESPACE],
		defaultNS: TRANSLATIONS_NAMESPACE,
		debug: log.getLevel() <= log.levels.DEBUG,
		interpolation: {
			escapeValue: false, // not needed for react
		},
		returnObjects: true,
		lng: locale,
		resources: {
			[locale]: {
				[TRANSLATIONS_NAMESPACE]: translations,
			},
		},
	});

	cache[locale] = instance;

	return instance;
}
