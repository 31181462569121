import styled from '@emotion/styled';
import { Color } from '../../../styles/Color';
import { BaseButton } from './BaseButton';

export const FilterButton = styled(BaseButton)<{
	isActive: boolean;
	hasIcon?: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: center;

	height: 40px;
	padding: ${({ hasIcon }) => (hasIcon ? '8px 10px 8px 14px' : '8px 14px')};
	border-radius: 8px;
	border: 1px solid ${Color.PrimaryLightGray};
	font-size: 16px;
	font-weight: bold;
	gap: ${({ hasIcon }) => (hasIcon ? '2px' : 'none')};

	background-color: ${({ isActive }) =>
		isActive ? Color.PrimaryBlack : Color.PrimaryWhite};
	color: ${({ isActive }) =>
		isActive ? Color.PrimaryWhite : Color.PrimaryBlack};
	transition: width 0.6s ease, background-color 0.4s ease;
`;
