import { UnifiedSearchStore } from '../domain/stores/createUnifiedSearchStore';

export type UnifiedSearchViewModel = ReturnType<
	typeof createUnifiedSearchViewModel
>;

export interface IUnifiedSearchViewModelProps {
	unifiedSearchStore: UnifiedSearchStore;
	isTransparent: boolean;
}

export const createUnifiedSearchViewModel = ({
	unifiedSearchStore,
	isTransparent,
}: IUnifiedSearchViewModelProps) => ({
	onClickToggle() {
		if (unifiedSearchStore.isActive) {
			unifiedSearchStore.reset();
		}

		unifiedSearchStore.setIsActive(!unifiedSearchStore.isActive);
	},
	unifiedSearchStore,
	isTransparent,
});
