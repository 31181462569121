import { getPublicConfig } from '../../config';
import { gtag } from './gtag';

export function trackPage(url: string) {
	const { legacyTrackingId } = getPublicConfig();

	// No need for manual page tracking for GA4, as it's supported out of the box.
	// We just need it for our legacy analytics
	if (legacyTrackingId) {
		trackPageForId(legacyTrackingId, url);
	}
}

function trackPageForId(trackingId: string, url: string) {
	gtag('config', trackingId, {
		page_path: url,
	});
}
