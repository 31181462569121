import React from 'react';
import type { AppContext, AppProps as BaseAppProps } from 'next/app';
import BaseApp from 'next/app';
import { ConfigProvider } from '@m2/blueprint-ui';
import '@emotion/core';
import '@reach/dialog/styles.css';

import log, { LogLevelDesc } from 'loglevel';
import { CommonDataProvider } from '../common-data/useCommonData';
import { ICommonData } from '../common-data/ICommonData';
import { getUiConfig } from '../config/getUiConfig';
import { GlobalStyles } from '../styles/GlobalStyles';
import { MarkdownRenderContextProvider } from '../components/common/markdown';
import { PageMetaData } from '../components/common/meta-data/PageMetaData';
import { Favicon } from '../components/common/meta-data/Favicon';
import { GoogleTagManager, PageTracking } from '../analytics';
import { getCommonData } from '../common-data/getCommonData';
import { CookieConsentDialog } from '../cookie-consent/CookieConsentDialog';
import { Chatbot } from '../chatbot/Chatbot';
import { isPreviewModeDisabled } from '../config/utils/isPreviewModeDisabled';
import { PreviewModeDisabled } from '../components/common/preview-mode-disabled/PreviewModeDisabled';
import { AuthContextProvider } from '../auth/common/context/AuthContextProvider';
import { I18nProvider } from '../i18n/translate/I18nProvider';

log.setLevel(process.env.LOG_LEVEL as LogLevelDesc);

interface IAppProps extends BaseAppProps {
	commonData: ICommonData;
	isPreview: boolean;
}

const App = ({ Component, pageProps, commonData, isPreview }: IAppProps) => {
	if (isPreviewModeDisabled(isPreview)) {
		return <PreviewModeDisabled />;
	}

	const { translations } = commonData;

	return (
		<>
			<PageMetaData
				title="DFW International Airport | Official Website"
				description="Welcome to DFW International Airport. View flight information, security wait times, parking, shopping &amp; dining options and more."
			/>
			<Favicon />
			<PageTracking />
			<GoogleTagManager />
			<Chatbot />
			<ConfigProvider config={getUiConfig()}>
				<AuthContextProvider>
					<CommonDataProvider value={commonData}>
						<I18nProvider translations={translations} locale="en">
							<GlobalStyles />
							<MarkdownRenderContextProvider>
								<Component {...pageProps} />
								<CookieConsentDialog />
							</MarkdownRenderContextProvider>
						</I18nProvider>
					</CommonDataProvider>
				</AuthContextProvider>
			</ConfigProvider>
		</>
	);
};

App.getInitialProps = async (appContext: AppContext) => {
	const { isPreview } = appContext.router;

	const appProps = await BaseApp.getInitialProps(appContext);
	const commonData = await getCommonData();

	return {
		...appProps,
		commonData,
		isPreview,
	};
};

App.displayName = 'App';

export default App;
