import React from 'react';
import styled from '@emotion/styled';
import { Button } from '@m2/blueprint-ui';
import { Color } from '../../styles/Color';

export interface IPopularSearchItem {
	title: string;
	onClick: (query: string) => void;
}
const PopularSearchItem = ({ title, onClick }: IPopularSearchItem) => (
	<StyledPopularSearchItem onClick={() => onClick(title)}>
		{title}
	</StyledPopularSearchItem>
);

export default PopularSearchItem;

const StyledPopularSearchItem = styled(Button)`
	height: 36px;
	padding: 8px 12px;

	color: ${Color.PrimaryWhite};
	background-color: ${Color.PrimaryOrange500};

	border: none;
	border-radius: 8px;

	font-size: 15px;
	font-weight: 500;
	line-height: 20px;

	&:hover:not(:disabled) {
		border: none;
	}
`;
