export function highlightText(text: string, query: string) {
	const index = text.toLowerCase().indexOf(query.toLowerCase());
	if (index >= 0) {
		return `${text.substring(0, index)}<em>${text.substring(
			index,
			index + query.length,
		)}</em>${text.substring(index + query.length)}`;
	}
	return text;
}
