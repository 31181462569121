import { PoiOpenStatus } from '@m2/blueprint-locuslabs-sdk';
import { IPoi } from '../types/IPoi';
import { IPoiCluster } from '../types/IPoiCluster';
import { getOrderedPoiItems } from './getOrderedPoiItems';

type PoiClusterMap = Record<string, IPoi[]>;

export function getClusteredPoisItems(pois: IPoi[]): Array<IPoi | IPoiCluster> {
	const clusterMap = pois.reduce((acc, current) => {
		if (acc[current.name]) {
			acc[current.name].push(current);
		} else {
			acc[current.name] = [current];
		}
		return acc;
	}, {} as PoiClusterMap);

	const clusteredPois = Object.entries(clusterMap).map(([name, children]) => {
		if (children.length === 1) {
			return children[0];
		}

		const openStatus = children.reduce((acc, current) => {
			if (current.openStatus === PoiOpenStatus.Open) {
				return PoiOpenStatus.Open;
			}
			if (
				acc !== PoiOpenStatus.Open &&
				current.openStatus === PoiOpenStatus.Closed
			) {
				return PoiOpenStatus.Closed;
			}
			return acc;
		}, PoiOpenStatus.Unknown);

		const orderedChildren = getOrderedPoiItems(children) as IPoi[];

		return {
			name,
			filterCategories: children[0].filterCategories,
			image: children[0].image ?? undefined,
			openStatus,
			children: orderedChildren,
		};
	});

	return clusteredPois;
}
