import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { AuthConfig } from '../config';

export const useAuthRedirect = (isLoading: boolean, isSignedIn: boolean) => {
	const router = useRouter();
	const [isRedirecting, setIsRedirecting] = useState(false);

	function redirect(page: string) {
		setIsRedirecting(true);
		router.push(`/${page}`).then(() => {
			setIsRedirecting(false);
		});
	}

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const currentPage = window.location.pathname.replace(/^\/|\/$/g, ''); // removing first and last slash bar

			const isLandedOnAuthPage = AuthConfig.signedInPages.find(
				(item) => item === currentPage,
			);
			const isLandedOnSignInPage = AuthConfig.signedOutPages.find(
				(item) => item === currentPage,
			);

			if (!isLoading && !isSignedIn && isLandedOnAuthPage) {
				redirect(AuthConfig.signedOutPages[0]);
			}

			if (!isLoading && isSignedIn && isLandedOnSignInPage) {
				redirect(AuthConfig.signedInPages[0]);
			}
		}
	}, [isLoading, isSignedIn, router]);

	return {
		isRedirecting,
	};
};
