export enum Categories {
	All = 'All Categories',
	Shops = 'Shops',
	Dine = 'Dine',
	Services = 'Services',
}

export const Services = 'fwiservice';

export const Shops = 'fwishop';

export const Dine = 'fwieat';
