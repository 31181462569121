import { IPathToPageIdMap, Path } from '@m2/blueprint-contentful-routes';

export function getUrlForPage(
	pathToPageIdMap: IPathToPageIdMap,
	pageId: string,
) {
	const matchedPage = Object.entries(pathToPageIdMap).find(
		([, id]) => id === pageId,
	);

	if (!matchedPage) {
		return undefined;
	}

	const [url] = matchedPage;

	return Path.from(url).toString();
}
