import styled from '@emotion/styled';

export const BaseButton = styled.button`
	padding: 0;
	text-align: inherit;
	color: inherit;
	border: none;
	background: transparent;
	cursor: pointer;
`;
