import { defaultMarkdownRenderers, IMarkdownRenderers } from '@m2/blueprint-ui';
import { ResponsiveImage } from './ResponsiveImage';

export const renderers: IMarkdownRenderers = {
	...defaultMarkdownRenderers,
	img: ResponsiveImage,
	h1: 'h2',
	h2: 'h4',
	h3: 'h5',
	h4: 'h5',
	h5: 'h5',
};
