import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useRemoteConfig } from '../config/useRemoteConfig';
import { getPublicConfig } from '../config';

export const Chatbot = observer(() => {
	const { value: chatbotEnabled } = useRemoteConfig('chatbot_enabled');
	const { chatBotUrl } = getPublicConfig();

	if (!chatBotUrl) {
		return <></>;
	}

	const ref = useRef<HTMLScriptElement | null>(null);
	useEffect(() => {
		if (!ref.current || !chatbotEnabled) return undefined;
		if (window.AirportAI) {
			loadChatbot(chatBotUrl);
			return undefined;
		}
		ref.current.addEventListener('load', () => loadChatbot(chatBotUrl));
		return () => {
			if (ref.current) {
				ref.current.removeEventListener('load', () => loadChatbot(chatBotUrl));
			}
		};
	}, [ref.current, chatbotEnabled, chatBotUrl]);

	useEffect(() => {
		window.addEventListener('click', closeChatBot);
		return () => {
			window.removeEventListener('click', closeChatBot);
		};
	});

	return (
		<>
			{/* Chatbot will be loaded here */}
			<div className="airportAI-chatPlugin" />
			<script
				ref={ref}
				id="airportAI-jssdk"
				type="text/javascript"
				async
				src={`${chatBotUrl}js/sdk/web.js`}
			/>
		</>
	);
});

const loadChatbot = (url: string) => {
	// airport AI expects the settings in window
	window.AirportAI_settings = {
		language: 'en',
		airportCode: 'DFW',
	};

	// Call the script that we loaded in the render bellow.
	window.AirportAI?.init({
		clientId: '9d8b48fe-5182-4703-a3f0-47ef431ea617',
		url,
	});
};

const closeChatBot = () => {
	window.AirportAI?.toggleChatPlugin(false);
};

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		AirportAI_settings: {
			language: string;
			airportCode: string;
		};
		AirportAI?: {
			init: (args: { clientId: string; url: string }) => void;
			toggleChatPlugin: (open: boolean) => void;
			removePlugins: () => void;
			renderPlugins: () => void;
		};
	}
}
