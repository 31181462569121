import { observable, action, reaction, makeObservable } from 'mobx';
import { IRequestOptions, RemoteDataStore } from '@m2/blueprint-utils';
import log from 'loglevel';

import { Skydive } from '../../../../skydive';
import {
	ISkydiveFlight,
	ISkydiveFlightsResponse,
} from '../../../../skydive/types';
import { logError } from '../../../../utils/logError';

type Params = Record<never, never>;

export class FlightStore extends RemoteDataStore<
	Params,
	ISkydiveFlightsResponse
> {
	public flights = observable([] as ISkydiveFlight[]);

	protected readonly api: Skydive;

	constructor(api: Skydive) {
		super();

		makeObservable<FlightStore, 'handleResponse'>(this, {
			handleResponse: action,
			clear: action,
		});

		this.api = api;

		reaction(() => this.error, this.onError);
	}

	public load() {
		return super.load({});
	}

	protected async performRequest(
		_params: Params = {},
		options: IRequestOptions,
	) {
		return this.api.flights().get(options);
	}

	protected handleResponse(response: ISkydiveFlightsResponse) {
		const flights = response.data.flight_list || ([] as ISkydiveFlight[]);
		log.info('Flights loaded', flights);

		this.flights.replace(flights);
	}

	public clear() {
		this.flights.clear();
	}

	private onError = () => {
		if (this.error) {
			logError(this.error, {});
		}
	};
}
