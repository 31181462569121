import log from 'loglevel';
import { IPathToPageIdMap } from '@m2/blueprint-contentful-routes';
import { useCommonData } from '../common-data/useCommonData';
import {
	IInternalLinkEntry,
	IExternalLinkEntry,
	EXTERNAL_LINK_TYPE_ID,
} from '../contentful';
import { getUrlForPage } from './getUrlForPage';

type Link = IInternalLinkEntry | IExternalLinkEntry;

export function getUrlForLink(
	link: Link,
	pathToPageIdMap: IPathToPageIdMap,
): string {
	if (link && link.contentType === EXTERNAL_LINK_TYPE_ID) {
		return link.fields.url;
	}

	const pageId = link?.fields.page.fields.id;
	const url = getUrlForPage(pathToPageIdMap, pageId);

	if (!url) {
		log.error('No URL available for Page ID:', pageId);

		return `/404/${pageId}`;
	}

	return url;
}

export function useUrlForLink(link: Link) {
	const { pathToPageIdMap } = useCommonData();

	return getUrlForLink(link, pathToPageIdMap);
}
