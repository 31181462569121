import { SITE_SECTION_TYPE_ID } from '@m2/blueprint-contentful';
import { IPathToPageIdMap } from '@m2/blueprint-contentful-routes';
import {
	CUSTOM_PAGES_CONTAINER_TYPE_ID,
	IGenericContentPageEntry,
} from '../contentful';
import { getUrlForPage } from './getUrlForPage';

export function getParentLink(
	page: IGenericContentPageEntry,
	pathToPageIdMap: IPathToPageIdMap,
): string | undefined {
	if (page.fields.parent?.contentType === SITE_SECTION_TYPE_ID) {
		return `/${page.fields.parent.fields.slug ?? ''}`;
	}
	if (
		page.fields.parent?.contentType === CUSTOM_PAGES_CONTAINER_TYPE_ID ||
		!page.fields.parent
	)
		return '/';
	return getUrlForPage(pathToPageIdMap, page.fields.parent?.fields.id);
}
