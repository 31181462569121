import { useState } from 'react';
import { useAuth } from '../context/AuthContext';

export const useLogout = () => {
	const { signOut } = useAuth();
	const [error, setError] = useState<Error>();

	const handleLogOut = async (options = { isDeleted: false }) => {
		try {
			await signOut({ isDeleted: options.isDeleted });
		} catch (e) {
			setError(e as Error);
		}
	};

	return {
		handleLogOut,
		error,
	};
};
