import { SuggestionGenerator } from '../types/SuggestionGenerator';
import { generateAirportIataSuggestions } from './generators/generateAirportIataSuggestions';
import { generateAirlineIataSuggestions } from './generators/generateAirlineIataSuggestions';
import { generateAirportCityNameSuggestions } from './generators/generateAirportCityNameSuggestions';
import { generateAirlineNameSuggestions } from './generators/generateAirlineNameSuggestions';
import { generateAirportNameSuggestions } from './generators/generateAirportNameSuggestions';
import { generateFlightMainFlightNumberSuggestions } from './generators/generateFlightMainFlightNumberSuggestions';
import { IFlightSearchSuggestion } from '../types/IFlightSearchSuggestion';

const generators = [
	generateAirlineIataSuggestions,
	generateAirlineNameSuggestions,
	generateAirportCityNameSuggestions,
	generateAirportIataSuggestions,
	generateAirportNameSuggestions,
	generateFlightMainFlightNumberSuggestions,
];

export const generateAutoSuggestions: SuggestionGenerator = (props) => {
	const lowerCaseProps = { ...props, userInput: props.userInput.toLowerCase() };

	return generators
		.map((generator) => generator(lowerCaseProps))
		.reduce((result, generator) => {
			result.push(...generator);
			return result;
		}, [] as IFlightSearchSuggestion[])
		.map((generator) => ({
			...generator,
			key: `${generator.filter}_${generator.stringValue}`,
		}))
		.filter(
			(suggestion, i, self) =>
				self.findIndex(({ key }) => key === suggestion.key) === i,
		);
};
