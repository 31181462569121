import { IEnrichedFlight } from '../../../../shared/domain/types/IEnrichedFlight';
import { AutoSuggestFilterField } from '../../types/AutoSuggestFilterField';
import { SuggestionGenerator } from '../../types/SuggestionGenerator';

export const generateFlightMainFlightNumberSuggestions: SuggestionGenerator = ({
	flights,
	userInput,
}) => [
	...flights
		.filter((flight: IEnrichedFlight) =>
			flight.mainFlightNumber.toLocaleLowerCase().includes(userInput),
		)
		.map((flight: IEnrichedFlight) => ({
			type: 'item' as const,
			filter: AutoSuggestFilterField.MainFlightNumber,
			value: flight.mainFlightNumber,
			stringValue: flight.mainFlightNumber,
		})),
];
