import { flightsTypeFilter } from '../../../search/domain/use-cases/flightsTypeFilter';
import { flightsSearchFilter } from '../../../search/domain/use-cases/flightsSearchFilter';
import { IEnrichedFlight } from '../../../shared/domain/types/IEnrichedFlight';
import { SearchFilters } from '../../../search/domain/types/SearchFilters';
import { SearchType } from '../../../search/domain/types/SearchType';

interface IFlightFilterOptions {
	searchFilter?: SearchFilters;
	searchType: SearchType;
}

export function getFilteredFlights(
	flights: IEnrichedFlight[],
	{ searchFilter, searchType }: IFlightFilterOptions,
) {
	return flights
		.sort((a, b) => a.scheduledTimestamp - b.scheduledTimestamp)
		.filter((flight: IEnrichedFlight) =>
			flightsSearchFilter(flight, searchFilter),
		)
		.filter((flight: IEnrichedFlight) => flightsTypeFilter(searchType, flight));
}
