import { FlightStore } from './FlightStore';
import { createFlightListStore } from '../../../list/domain/stores/createFlightListStore';
import { Skydive } from '../../../../skydive';
import { AirlineStore } from '../../../../airlines/stores';
import { AirportStore } from '../../../../airports/stores';
import { createFlightEnrichStore } from './createFlightEnrichStore';
import { WeatherStore } from '../../../detail/domain/stores/WeatherStore';

export function createFlightStores(skydive: Skydive) {
	const flightStore = new FlightStore(skydive);

	const airlineStore = new AirlineStore();
	const airportStore = new AirportStore();

	const flightEnrichStore = createFlightEnrichStore(airlineStore, airportStore);

	const flightListStore = createFlightListStore(flightStore, flightEnrichStore);
	const weatherStore = new WeatherStore(skydive);

	return {
		airlineStore,
		airportStore,
		flightListStore,
		weatherStore,
		flightStore,
	};
}
