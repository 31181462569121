import { AirlineStore } from '../../../../airlines/stores';
import { IAirline } from '../../../../airlines/types';
import { AirportStore } from '../../../../airports/stores';
import { IAirport } from '../../../../airports/types';
import { IFlight } from '../types/IFlight';

export function createFlightEnrichers(
	airlineStore: AirlineStore,
	airportStore: AirportStore,
) {
	const airlineAndAirportEnricher = (flight: IFlight) => {
		const { icaoMainPrefix, originAirport, destinationAirport } = flight;

		const airlineDetails = airlineStore.airlines.find(
			(airline: IAirline) => airline.icao === icaoMainPrefix,
		);
		const originAirportDetails = airportStore.airports.find(
			(airport: IAirport) => airport.iata === originAirport,
		);
		const destinationAirportDetails = airportStore.airports.find(
			(airport: IAirport) => airport.iata === destinationAirport,
		);

		return {
			...flight,
			airlineDetails,
			originAirportDetails,
			destinationAirportDetails,
		};
	};

	return { airlineAndAirportEnricher };
}
