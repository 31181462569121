import { css, Global } from '@emotion/core';
import { Responsive } from '@m2/blueprint-ui';
import React from 'react';

export const Table = () => {
	const styles = css`
		table,
		tbody,
		tfoot,
		thead,
		tr,
		th,
		td {
			margin: 0;
			padding: 0;
			border: 0;
			font-size: 100%;
			font: inherit;
			vertical-align: baseline;
		}
		table {
			border-collapse: collapse;
			border-spacing: 0;
		}

		th {
			text-align: left;
			padding-right: 50px;
			font-weight: bold;

			@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
				padding-right: 25px;
			}

			@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
				padding-right: 10px;
			}
		}

		td {
			padding-right: 50px;

			@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
				padding-right: 25px;
			}

			@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
				padding-right: 10px;
			}
		}
	`;

	return <Global styles={styles} />;
};
