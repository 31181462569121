import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '@m2/blueprint-ui';
import { UnifiedSearchEmptyStateViewModel } from './UnifiedSearchEmptyStateViewModel';
import PopularSearchItem from './PopularSearchItem';
import { Color } from '../../styles/Color';
import { useCommonData } from '../../common-data/useCommonData';
import { useTranslation } from '../../i18n/translate/useTranslation';

export interface IUnifiedSearchEmptyStateProps {
	viewModel: UnifiedSearchEmptyStateViewModel;
	onClick: (search: string) => void;
}

export const UnifiedSearchEmptyState = ({
	viewModel: { title, body },
	onClick,
}: IUnifiedSearchEmptyStateProps) => {
	const { t } = useTranslation();
	const { unifiedSearches } = useCommonData();

	return (
		<StyledUnifiedSearchEmptyState>
			<UnifiedSearchEmptyDescription
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<UnifiedSearchEmptyStateTitle>{t(title)}</UnifiedSearchEmptyStateTitle>
				<UnifiedSearchEmptyStateBody>{t(body)}</UnifiedSearchEmptyStateBody>
			</UnifiedSearchEmptyDescription>
			<UnifiedSearchPopularItems alignItems="center" wrap="wrap">
				{unifiedSearches?.popularSearches.map((item) => (
					<PopularSearchItem title={item} onClick={onClick} />
				))}
			</UnifiedSearchPopularItems>
		</StyledUnifiedSearchEmptyState>
	);
};

const StyledUnifiedSearchEmptyState = styled.div`
	max-width: 536px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 24px;

	margin: 0 auto;
`;

const UnifiedSearchEmptyDescription = styled(Flex)`
	gap: 8px;
`;

const UnifiedSearchEmptyStateTitle = styled.h3`
	color: ${Color.PrimaryBlack};
	margin: 0;
`;

const UnifiedSearchEmptyStateBody = styled.p`
	color: ${Color.PrimaryDarkGray100};
	font-size: 15px;
	text-align: center;
	margin: 0;
`;

const UnifiedSearchPopularItems = styled(Flex)`
	width: 100%;
	flex-direction: row;
	gap: 8px;
`;
