import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
	ITheme,
	Link,
	Responsive,
	StyledLinkText,
	themed,
} from '@m2/blueprint-ui';
import { observer } from 'mobx-react';

import { css, keyframes } from '@emotion/core';
import dfwLogoBrand from '../../../../assets/dfw-logo-brand.svg?reference';
import dfwLogoInverted from '../../../../assets/dfw-logo-inverted.svg?reference';
import { TopNavigationMobile } from './TopNavigationMobile';
import { OrangeLine } from './OrangeLine';
import {
	NAVIGATION_MENU_MARGIN_XL,
	NAVIGATION_PADDING_L,
	NAVIGATION_PADDING_M,
	NAVIGATION_PADDING_S,
	NAVIGATION_PADDING_XL,
} from './constants';

import { Color } from '../../../../styles/Color';
import { useCurrentSiteSection } from '../../../../navigation';
import { useCommonData } from '../../../../common-data/useCommonData';
import { useIsScrolledDown } from '../hooks/useIsScrolledDown';
import { useScrollPosition } from '../hooks/useScrollPosition';
import { DropDownMenu } from './dropdown-menu/desktop/DropDownMenu';
import { UnifiedSearch } from '../../../../unified-search/ui/UnifiedSearch';
import { createUnifiedSearchViewModel } from '../../../../unified-search/ui/UnifiedSearchViewModel';
import { useUnifiedSearchStore } from '../../../../unified-search/domain/stores/useUnifiedSearchStore';
import { AccountMenuItem } from '../../../../account/home/ui/AccountMenuItem';

export interface ITopNavigationProps {
	setSearchActive?: (bool: boolean) => void;
	hasHeaderImage?: boolean;
}

export const TopNavigation = observer(
	({ setSearchActive, hasHeaderImage }: ITopNavigationProps) => {
		const [mobileNavVisibility, setMobileNavVisibility] =
			useState<boolean>(false);
		const headerRef = useRef<HTMLDivElement>(null);

		const { pathToPageIdMap } = useCommonData();
		const isScrolledDown = useIsScrolledDown(headerRef);

		const currentSiteSection = useCurrentSiteSection();
		const menuItems = currentSiteSection?.fields?.menuItems;

		const unifiedSearchStore = useUnifiedSearchStore();
		const isTransparent = unifiedSearchStore.isActive
			? false
			: !isScrolledDown && (hasHeaderImage ?? false);

		const unifiedSearchViewModel = createUnifiedSearchViewModel({
			unifiedSearchStore,
			isTransparent,
		});

		useEffect(() => {
			if (setSearchActive) {
				setSearchActive(unifiedSearchStore.isActive);
			}
		}, [unifiedSearchStore.isActive]);

		return (
			<StyledNavigationWrapper
				isScrolledDown={isScrolledDown}
				isTransparent={isTransparent}
				mobileNavVisibility={mobileNavVisibility}
				ref={headerRef}
			>
				<TopNavigationMobile
					isScrolledDown={isScrolledDown}
					isTransparent={isTransparent}
					mobileNavVisibility={mobileNavVisibility}
					setMobileNavVisibility={setMobileNavVisibility}
				/>
				<StyledImageWrapper>
					<Link href="/">
						<img
							src={
								isTransparent || mobileNavVisibility
									? dfwLogoInverted
									: dfwLogoBrand
							}
							alt="Dallas Fort Worth International Airport"
						/>
					</Link>
				</StyledImageWrapper>
				<ScrollProgressBar isTransparent={isTransparent} />
				<StyledDropDownMenu>
					<DropDownMenu
						pathToPageIdMap={pathToPageIdMap}
						menuItems={menuItems}
						isScrolledDown={isScrolledDown}
						isTransparent={isTransparent}
					/>
				</StyledDropDownMenu>
				<AccountMenuItem isTransparent={isTransparent} />
				<StyledRightContainer>
					<UnifiedSearch viewModel={unifiedSearchViewModel} />
				</StyledRightContainer>
			</StyledNavigationWrapper>
		);
	},
);

interface IScrollProgressBarProps {
	isTransparent: boolean;
}

const ScrollProgressBar = ({ isTransparent }: IScrollProgressBarProps) => {
	const scrollPosition = useScrollPosition();
	return (
		<WrapperOrangeLine isVisible={isTransparent}>
			<OrangeLine
				yTotal={scrollPosition.total}
				yPosition={scrollPosition.current}
			/>
		</WrapperOrangeLine>
	);
};

export interface IStyledProps {
	isImageHeader?: boolean;
	mobileNavVisibility?: boolean;
	isCtaHeader?: boolean;
	isHomePage?: boolean;
	isTransparent?: boolean;
	isScrolledDown?: boolean;
}

const StyledDropDownMenu = styled.div`
	width: 100%;
	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		display: none;
	}
`;

export const WrapperOrangeLine = styled.div<{ isVisible: boolean }>`
	display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
	position: absolute;
	left: 0;
	bottom: 0;
`;

const backgroundFadeIn = keyframes`
  from {
    background: rgba(255, 255, 255, 0);
  }
  to {
	  background: rgba(255, 255, 255, 255);
  }
`;

export const StyledNavigationWrapper = styled.div<IStyledProps, ITheme>`
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	font-size: 16px;
	color: ${themed(({ color }) => color.text.default)};
	background-color: ${themed(({ background }, { isTransparent }) =>
		isTransparent ? 'transparent' : background.default,
	)};
	max-height: 80px;
	box-shadow: ${({ isScrolledDown, isTransparent }) =>
		isScrolledDown
			? '0 2px 4px 0 rgba(0, 0, 0, 0.1);'
			: !isTransparent
			? '0 2px 4px 0 rgba(0, 0, 0, 0.1);'
			: '0'};
	margin-bottom: -80px;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: ${NAVIGATION_PADDING_XL};
	padding-right: ${NAVIGATION_PADDING_XL};
	display: flex;
	z-index: 2000;
	align-items: center;
	justify-content: flex-start;
	gap: 2vw;
	animation: ${({ isTransparent }) =>
		!isTransparent
			? css`
					${backgroundFadeIn} 0.7s ease
			  `
			: 'none'};

	@media (max-width: 1300px) {
		padding-left: ${NAVIGATION_PADDING_L};
		padding-right: ${NAVIGATION_PADDING_L};
	}

	@media (max-width: 1100px) {
		padding-left: ${NAVIGATION_PADDING_M};
		padding-right: ${NAVIGATION_PADDING_M};
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding-left: ${NAVIGATION_PADDING_S};
		padding-right: ${NAVIGATION_PADDING_S};
	}
`;

export const StyledMenuItems = styled.div<{}, ITheme>`
	display: flex;

	> * {
		margin-right: ${NAVIGATION_MENU_MARGIN_XL};

		@media (max-width: 1300px) {
			margin-right: ${NAVIGATION_MENU_MARGIN_XL};
		}

		span {
			@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
				display: none;
			}
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		display: none;
	}
`;

export const StyledDropdownLogoWrapper = styled.div<{}, ITheme>`
	z-index: 2100;
	display: flex;
	align-items: center;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		display: none;
	}
`;

export const StyledMenuItemLink = styled.div<IStyledProps, ITheme>`
	cursor: pointer;

	${StyledLinkText} {
		color: ${themed(({ color }, { isTransparent }) =>
			isTransparent ? color.text.inverted : color.text.default,
		)};
	}
`;

export const StyledImageWrapper = styled.div<{}, ITheme>`
	cursor: pointer;
	z-index: 2200;

	img {
		display: block;
		width: 129px;
		height: 64px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		display: none;
	}
`;

export const StyledMobileMenuWrapper = styled.div``;

export const StyledMobileMenuIcon = styled.button<IStyledProps, ITheme>`
	display: ${({ mobileNavVisibility }) =>
		mobileNavVisibility ? 'flex' : 'none'};

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		display: flex;
		background-color: transparent;
		border: 0;
		z-index: 1003;
	}
`;

export const StyledMobileMenuBackground = styled.div<{}, ITheme>`
	width: 100%;
	height: 100vh;
	background-color: white;
	z-index: 1002;
	position: absolute;
	top: 0;
	left: 0;
	padding-top: 88px;
	padding-left: 32px;
	padding-right: 32px;
`;

export const StyledMobileSiteSectionWrapper = styled.div<{}, ITheme>`
	display: flex;
	flex-direction: column;
	margin-bottom: 50px;
`;

export const StyledMobileSiteSectionItem = styled.button<{}, ITheme>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 35px;
	border: 0;
	border-bottom: 1px solid ${Color.PrimaryLightGray};
	background-color: transparent;
	font-family: inherit;
	font-weight: bold;
	font-size: 18px;
	color: ${Color.PrimaryDarkGray100};

	&:hover {
		color: ${themed(({ color }) => color.brand)};
		cursor: pointer;
	}
`;

export const StyledMobileMenuItem = styled.button<{}, ITheme>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border: 0;
	font-family: inherit;
	font-size: 16px;
	background-color: transparent;
	color: ${Color.PrimaryDarkGray100};
	margin-top: 24px;
	margin-bottom: 24px;

	&:hover {
		color: ${themed(({ color }) => color.brand)};
		cursor: pointer;
	}
`;

const StyledRightContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: auto;
`;
