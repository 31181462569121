import { observable, action, makeObservable } from 'mobx';
import Cookies from 'js-cookie';
import { isServerSide } from '../utils/isServerSide';

const COOKIE_CONSENT_KEY = 'cookie-consent';
const COOKIE_CONSENT_VALUE = '1';

// 10 years
const COOKIE_CONSENT_EXPIRES = 365 * 10;

export class CookieConsentStore {
	private _hasConsent = false;

	private _isInitialized = false;

	constructor() {
		makeObservable<
			CookieConsentStore,
			'_hasConsent' | '_isInitialized' | 'loadFromCookie'
		>(this, {
			_hasConsent: observable,
			_isInitialized: observable,
			setConsent: action,
			loadFromCookie: action,
		});
	}

	get hasConsent() {
		return this._hasConsent;
	}

	get isInitialized() {
		return this._isInitialized;
	}

	init() {
		if (this._isInitialized) {
			return;
		}

		if (isServerSide()) {
			return;
		}

		this._isInitialized = true;

		this.loadFromCookie();
	}

	setConsent() {
		this._hasConsent = true;

		Cookies.set(COOKIE_CONSENT_KEY, COOKIE_CONSENT_VALUE, {
			expires: COOKIE_CONSENT_EXPIRES,
		});
	}

	private loadFromCookie() {
		this._hasConsent = Cookies.get(COOKIE_CONSENT_KEY) === COOKIE_CONSENT_VALUE;
	}
}
