export enum DineTags {
	AmericanCuisine = 'American Cuisine',
	Asian = 'Asian',
	Bar = 'Bar',
	Barbecue = 'Barbecue',
	CoffeeAndTea = 'Coffee & Tea',
	DessertsAndSnacks = 'Desserts & Snacks',
	FastFood = 'Fast Food',
	GrandHyattDining = 'Grand Hyatt Dining',
	ItalianPizza = 'Italian/Pizza',
	Mediterranean = 'Mediterranean',
	MexicanSouthwest = 'Mexican/Southwest',
	SandwichDeli = 'Sandwich/Deli',
	Seafood = 'Seafood',
}

export enum ServicesTags {
	AirlineClubs = 'Airline Clubs',
	Business = 'Business',
	ChildrenPlayAreas = "Children's Play Areas",
	Entertainment = 'Entertainment',
	InternetAccess = 'Internet Access',
	Personal = 'Personal',
	PowerCharging = 'Power Charging',
	TechnologyServices = 'Technology Services',
	Travel = 'Travel',
}

export enum ShopTags {
	AccessoriesAndGifts = 'Accessories & Gifts',
	Apparel = 'Apparel',
	BooksAndNews = 'Books & News',
	Children = "Children's",
	ConvenienceStore = 'Convenience Store',
	CosmeticsAndFragrances = 'Cosmetics and Fragrances',
	DutyFree = 'Duty Free',
	Electronics = 'Electronics',
	Sports = 'Sports',
	TaxFree = 'Tax Free',
	TexasGifts = 'Texas Gifts',
}

export const AllTagsLabel = 'All Categories';
export const CombinedTags = { ...DineTags, ...ServicesTags, ...ShopTags };

export const DineTagsArray = [AllTagsLabel, ...Object.values(DineTags)];
export const ServicesTagsArray = [AllTagsLabel, ...Object.values(ServicesTags)];
export const ShopTagsArray = [AllTagsLabel, ...Object.values(ShopTags)];
export const AllTagsArray = [AllTagsLabel, ...Object.values(CombinedTags)];
