import {
	IPoi as IBasePoi,
	getAllPois,
	ILocusMaps,
} from '@m2/blueprint-locuslabs-sdk';
import { IPoi } from '../types/IPoi';
import { getFilterCategories } from './getFilterCategories';

export interface IGetAllPois {
	(): Promise<IPoi[]>;
}

function mapPoi(poi: IBasePoi): IPoi | undefined {
	const categories = getFilterCategories(poi);

	if (categories.length === 0) {
		return undefined;
	}

	return {
		...poi,
		filterCategories: categories,
	};
}

function mapPois(basePois: IBasePoi[]): IPoi[] {
	const pois: IPoi[] = [];

	for (let i = 0; i < basePois.length; i += 1) {
		const poi = mapPoi(basePois[i]);
		if (poi) {
			pois.push(poi);
		}
	}
	return pois;
}

export const createGetAllPois = async (locusMaps: ILocusMaps) => {
	const basePois = await getAllPois(locusMaps);
	return mapPois(basePois);
};
