import { AxiosRequestConfig } from 'axios';
import { ApiClient } from '@m2/blueprint-utils';
import { AbstractService } from './AbstractService';
import { ISkydiveShuttleStopEtasResponse } from '../types/ISkydiveShuttleStopEtasResponse';

export class ShuttleStopEtasService extends AbstractService {
	/**
	 * Path of the resource to be used by the service.
	 */
	private readonly baseUriPath: string;

	public static readonly URI_PATH = '/etas';

	constructor(client: ApiClient, uriPath: string) {
		super(client); // Pass the client to the superclass constructor
		this.baseUriPath = uriPath;
	}

	public getShuttleStopEtas(
		requestConfig: AxiosRequestConfig = {},
	): Promise<ISkydiveShuttleStopEtasResponse> {
		const uri = this.baseUriPath + ShuttleStopEtasService.URI_PATH;

		return this.client.request(uri, {
			method: 'GET',
			...requestConfig,
		});
	}
}
