import { makeAutoObservable } from 'mobx';
import { IAsyncStore } from '@m2/blueprint-utils';

import { FlightStore } from '../../../shared/domain/stores/FlightStore';
import { FlightEnrichStore } from '../../../shared/domain/stores/FlightEnrichStore';
import { IEnrichedFlight } from '../../../shared/domain/types/IEnrichedFlight';
import { getFilteredFlights } from '../use-cases/getFilteredFlights';
import { SearchFilters } from '../../../search/domain/types/SearchFilters';
import { SearchType } from '../../../search/domain/types/SearchType';

export interface IFlightListStore extends IAsyncStore {
	readonly finishedInitialLoad: boolean;
	readonly searchFilter: { [p: string]: string } | undefined;
	readonly searchType: SearchType;
	load: () => Promise<void>;
	cancelCurrentRequest: () => Promise<void>;
	setSearchFilter: (searchFilter: SearchFilters | undefined) => void;
	setSearchType: (searchType: SearchType) => void;
	getFlightsByNumber: (flightNumber: string) => IEnrichedFlight[];
	readonly flights: IEnrichedFlight[];
}

export class FlightListStore implements IFlightListStore {
	_searchFilter?: SearchFilters = undefined;

	_searchType: SearchType = SearchType.All;

	constructor(
		private readonly flightStore: FlightStore,
		private readonly flightEnrichStore: FlightEnrichStore,
	) {
		makeAutoObservable(this);
	}

	public get finishedInitialLoad() {
		return this.flightStore.finishedInitialLoad;
	}

	public get isLoading() {
		return this.flightStore.isLoading;
	}

	public get error() {
		return this.flightStore.error;
	}

	protected get enrichedFlights(): IEnrichedFlight[] {
		return this.flightEnrichStore.enrich(this.flightStore.flights);
	}

	public get flights() {
		return getFilteredFlights(this.enrichedFlights, {
			searchFilter: this.searchFilter,
			searchType: this.searchType,
		});
	}

	public async load() {
		await this.flightStore.load();
	}

	public async cancelCurrentRequest() {
		await this.flightStore.cancelCurrentRequest();
	}

	public setSearchFilter(searchFilter: SearchFilters = {}) {
		const isEmptyQuery = Object.values(searchFilter).every((value) => !value);

		this._searchFilter = isEmptyQuery ? undefined : searchFilter;
	}

	public setSearchType(searchType: SearchType) {
		this._searchType = searchType;
	}

	get searchType(): SearchType {
		return this._searchType;
	}

	get searchFilter(): SearchFilters | undefined {
		return this._searchFilter;
	}

	getFlightsByNumber(flightNumber: string) {
		return this.enrichedFlights.filter(
			(flight) => flight.mainFlightNumber === flightNumber,
		);
	}
}
