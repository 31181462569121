import React, {
	AnchorHTMLAttributes,
	FunctionComponent,
	KeyboardEvent,
	MouseEvent,
} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {
	CancelIcon,
	ChevronRightIcon,
	InfoIcon,
	ITheme,
	LinkText,
	LocationIcon,
	PlaneTopIcon,
	Responsive,
} from '@m2/blueprint-ui';
import Link from 'next/link';
import { SearchResultItemViewModel } from './SearchResultItemViewModel';
import { TypeFilterField } from '../domain/types/TypeFilterField';
import { Url } from '../../navigation';
import { Color } from '../../styles/Color';

export interface ISearchResultItemProps {
	viewModel: SearchResultItemViewModel;
	isRecentSearchesMode?: boolean;
	onRemove?: () => void;
}

const PossibleLink: FunctionComponent<
	{ url?: Url } & AnchorHTMLAttributes<HTMLAnchorElement | HTMLDivElement>
> = ({ url, children, onKeyDown, onClick }) => {
	if (url) {
		return (
			<Link href={url}>
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<a tabIndex={0} onKeyDown={onKeyDown} role="button" onClick={onClick}>
					{children}
				</a>
			</Link>
		);
	}
	return (
		<div tabIndex={0} onKeyDown={onKeyDown} role="button" onClick={onClick}>
			{children}
		</div>
	);
};

export const SearchResultItem = ({
	isRecentSearchesMode,
	onRemove,
	viewModel: {
		item: { title, description },
		onClick,
		contentType,
		url,
	},
}: ISearchResultItemProps) => {
	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'Enter') {
			onClick();
		}
	};

	const handleOnClick = (event: MouseEvent) => {
		// Don't bubble up to other click events handlers, this will prevent the search modal from closing
		event.stopPropagation();
		onClick();
	};

	const handleRemoveRecentSearchClick = (event: MouseEvent) => {
		event.stopPropagation();
		event.preventDefault();

		if (onRemove) {
			onRemove();
		}
	};

	return (
		<StyledResultItem>
			<PossibleLink url={url} onClick={handleOnClick} onKeyDown={handleKeyDown}>
				<StyledResultItemContent>
					<StyledItemIcon>
						{contentType === TypeFilterField.Flights && <StyledPlaneTopIcon />}
						{contentType === TypeFilterField.Pois && <LocationIcon />}
						{contentType === TypeFilterField.Content && <StyledInfoIcon />}
					</StyledItemIcon>
					<StyledContent isRecentSearchesMode={isRecentSearchesMode}>
						<LinkText>
							<StyledItemTitle
								dangerouslySetInnerHTML={{
									// eslint-disable-next-line @typescript-eslint/naming-convention
									__html: title,
								}}
							/>
							{description && (
								<StyledItemDescription
									dangerouslySetInnerHTML={{
										// eslint-disable-next-line @typescript-eslint/naming-convention
										__html: description,
									}}
								/>
							)}
						</LinkText>
					</StyledContent>
					{isRecentSearchesMode ? (
						<StyledCancelIcon
							role="button"
							aria-label="Remove recent search"
							onClick={handleRemoveRecentSearchClick}
						/>
					) : (
						<StyledChevronRightIcon />
					)}
				</StyledResultItemContent>
			</PossibleLink>
		</StyledResultItem>
	);
};

const StyledResultItem = styled.div`
	a:hover {
		text-decoration: none;
	}
`;

const StyledContent = styled.div<{ isRecentSearchesMode?: boolean }, ITheme>`
	flex: 1;

	& em {
		font-style: normal;
		${({ isRecentSearchesMode }) =>
			!isRecentSearchesMode &&
			css`
				font-weight: bold;
				color: ${Color.PrimaryBlack};
			`};
	}
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
	> g {
		stroke: ${Color.PrimaryDarkGray40};
	}
`;

const StyledCancelIcon = styled(CancelIcon)`
	> g {
		stroke: ${Color.PrimaryDarkGray40};
	}
`;

const StyledItemTitle = styled.h3`
	font-size: 17px;
	font-weight: 400;
	margin: 0px;
	color: #1a1a1a;

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		max-width: 230px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

const StyledItemDescription = styled.p`
	font-size: 15px;
	font-weight: 400;
	color: ${Color.Neutral500};
	margin-bottom: 0;

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		max-width: 230px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

const StyledResultItemContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	padding: 12px;
	height: 70px;
	background-color: ${Color.PrimaryWhite};
	border-radius: 8px;
`;

const StyledItemIcon = styled.div`
	margin-right: 12px;
	line-height: 0;

	path {
		fill: ${Color.Neutral500};
	}
`;

const StyledPlaneTopIcon = styled(PlaneTopIcon)`
	transform: rotate(90deg);
`;

const StyledInfoIcon = styled(InfoIcon)`
	width: 20px;
	height: 20px;
	margin-left: 3px;
	margin-right: 2px;
`;
