import { IPathToPageIdMap } from '@m2/blueprint-contentful-routes';
import { PoiStore } from '../../shop-dine-services/domain/stores/createPoiStore';
import { UnifiedSearchStore } from '../domain/stores/createUnifiedSearchStore';
import { createSearchOverlayViewModel } from './SearchOverlayViewModel';
import { createUnifiedSearchMenuToggleViewModel } from './UnifiedSearchMenuToggleViewModel';
import { ITranslationFunction } from '../../i18n/translate/useTranslation';

export type UnifiedSearchMenuViewModel = ReturnType<
	typeof createUnifiedSearchMenuViewModel
>;
export interface IUnifiedSearchMenuViewModelProps {
	poiStore: PoiStore;
	isTransparent: boolean;
	unifiedSearchStore: UnifiedSearchStore;
	pathToPageIdMap: IPathToPageIdMap;
	t: ITranslationFunction;
}

export const createUnifiedSearchMenuViewModel = ({
	poiStore,
	isTransparent,
	unifiedSearchStore,
	pathToPageIdMap,
	t,
}: IUnifiedSearchMenuViewModelProps) => ({
	menuToggleViewModel: createUnifiedSearchMenuToggleViewModel({
		unifiedSearchStore,
		isTransparent,
	}),
	overlayViewModel: createSearchOverlayViewModel({
		poiStore,
		unifiedSearchStore,
		pathToPageIdMap,
		t,
	}),
});
