import {
	FirebaseAppStore,
	FirebaseRemoteConfigStore,
} from '@m2/blueprint-firebase';
import { remoteConfigSchema } from './types/RemoteConfigSchema';
import { IRemoteConfig } from './types/IRemoteConfig';
import { BuildConfig } from './getBuildConfig';

export function createRemoteConfigStore(buildConfig: BuildConfig) {
	const appConfig = {
		apiKey: buildConfig.firebaseApiKey,
		authDomain: buildConfig.firebaseAuthDomain,
		databaseURL: buildConfig.firebaseDatabaseUrl,
		projectId: buildConfig.firebaseProjectId,
		storageBucket: buildConfig.firebaseStorageBucket,
		messagingSenderId: buildConfig.firebaseMessagingSenderId,
		appId: buildConfig.firebaseAppId,
	};

	const appStore = new FirebaseAppStore(appConfig);
	const remoteConfigStore = new FirebaseRemoteConfigStore<
		typeof remoteConfigSchema,
		IRemoteConfig
	>(appStore, remoteConfigSchema, {
		chatbot_enabled: false,
		security_enabled: false,
		unified_search_enabled: false,
		shuttle_tracking_qr_enabled: false,
		poi_open_status_enabled: false,
	});

	return remoteConfigStore;
}
