import { getPublicConfig } from '../../config';

const { awsRegion, awsUserPoolId, awsUserPoolWebClientId } = getPublicConfig();

export const cognitoConfig = {
	// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
	identityPoolId: process.env.NEXT_PUBLIC_AWS_IDENTITY_POOL_ID,

	// REQUIRED - Amazon Cognito Region
	region: awsRegion,

	// OPTIONAL - Amazon Cognito User Pool ID
	userPoolId: awsUserPoolId,

	// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
	userPoolWebClientId: awsUserPoolWebClientId,

	// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
	mandatorySignIn: false,
};
