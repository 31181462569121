import styled from '@emotion/styled';
import { Button, Link, Responsive, StyledButton } from '@m2/blueprint-ui';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useUrlForPage } from '../utils';
import { Color } from '../styles/Color';
import { useCookieConsentStore } from './useCookieConsentStore';

const COOKIE_POLICY_PAGE_ID = 'cookie-policy';

export const CookieConsentDialog = observer(() => {
	const { cookieConsentStore } = useCookieConsentStore();
	const cookiePolicyPageUrl = useUrlForPage(COOKIE_POLICY_PAGE_ID);
	const router = useRouter();

	useEffect(() => {
		cookieConsentStore.init();
	}, [cookieConsentStore]);

	const handleClickContinue = () => {
		cookieConsentStore.setConsent();
	};

	// Don't show the dialog on the Cookie Policy page
	if (router.asPath === cookiePolicyPageUrl) {
		return null;
	}

	return (
		<StyledDialogOverlay isOpen={!cookieConsentStore.hasConsent}>
			<CookieBannerContent
				onClickContinue={handleClickContinue}
				cookiePolicyPageUrl={cookiePolicyPageUrl}
			/>
		</StyledDialogOverlay>
	);
});

const StyledDialogOverlay = styled(DialogOverlay)`
	z-index: 6001;
	overflow: visible;
`;

interface ICookieBannerContentProps {
	onClickContinue: () => void;
	cookiePolicyPageUrl?: string;
}

const CookieBannerContent = ({
	onClickContinue,
	cookiePolicyPageUrl,
}: ICookieBannerContentProps) => (
	<StyledDialogContent aria-label="Cookie Consent dialog" tabIndex={0}>
		<Header>Privacy and Cookies Policy</Header>
		<p>
			We use cookies, small files stored on your device, to give you the best
			customer experience. By clicking &apos;Accept&apos;, you are agreeing to
			our use of cookies.
			{cookiePolicyPageUrl && (
				<>
					{' '}
					<Link href={cookiePolicyPageUrl}>Learn More</Link>
				</>
			)}
		</p>
		<Button type="button" onClick={onClickContinue}>
			Accept and Continue
		</Button>
	</StyledDialogContent>
);

const Header = styled.p`
	color: ${Color.PrimaryDarkGray100};
	font-size: 18px;
`;

const StyledDialogContent = styled(DialogContent)`
	width: 620px;
	max-width: 90vw;
	margin: 50vh auto 0;
	max-height: 100vh;
	transform: translateY(-50%);
	border-radius: 8px;
	padding: 56px 80px;
	outline: none;

	${StyledButton} {
		margin-top: 24px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding: 24px 40px;
	}
`;
