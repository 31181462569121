import React, { useContext } from 'react';
import { ICognitoUser } from '../../../common/cognito/types/ICognitoUser';

interface IAuthContext {
	isSignedIn: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	signOut: ({ isDeleted }: { isDeleted?: boolean }) => Promise<void>;
	user?: ICognitoUser;
	isLoading: boolean;
	isRedirecting: boolean;
	isDeleted: boolean;
}

export const AuthContext = React.createContext<IAuthContext>(
	{} as IAuthContext,
);

export const useAuth = () => useContext(AuthContext);
