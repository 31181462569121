import { makeAutoObservable } from 'mobx';
import { IAlgoliaSearchService } from '../../data/types/IAlgoliaSearchService';
import { IEntrySearchResultResponse } from '../../data/types/IEntrySearchResultResponse';
import { IContentSearchResult } from '../types/IContentSearchResult';

export type ContentSearchStore = ReturnType<typeof createContentSearchStore>;

interface IContentSearchStore {
	algoliaSearchService: IAlgoliaSearchService;
}

export function createContentSearchStore({
	algoliaSearchService,
}: IContentSearchStore) {
	return makeAutoObservable({
		userQuery: '',
		content: [] as IContentSearchResult[],

		reset() {
			this.setUserQuery('');
			this.setContent([]);
		},

		setUserQuery(userQuery: string) {
			this.userQuery = userQuery;

			if (this.userQuery) {
				this.fetchContentResults();
			}
		},

		async fetchContentResults() {
			const results = await algoliaSearchService.getSearchResults(
				this.userQuery,
			);
			const searchResults = results
				.map(mapResultToContentSearchResult)
				.filter((item): item is IContentSearchResult => item !== undefined);

			// Removes duplicate pageIds to prevent duplicate search items
			const setOfUniqueSearchResults = new Set<string>();

			const uniqueResults = searchResults.filter((result) => {
				if (setOfUniqueSearchResults.has(result.pageId)) {
					return false;
				}
				setOfUniqueSearchResults.add(result.pageId);
				return true;
			});

			this.setContent(uniqueResults);
		},

		setContent(content: IContentSearchResult[]) {
			this.content = content;
		},
	});
}

function mapResultToContentSearchResult(
	result: IEntrySearchResultResponse,
): IContentSearchResult | undefined {
	if (result.pageId == null) {
		return undefined;
	}
	return {
		pageId: result.pageId,
		slug: result.slug,
		id: result.objectID,
		// eslint-disable-next-line no-underscore-dangle
		snippet: result._snippetResult,
	};
}
