import algoliasearch from 'algoliasearch';

import { IAlgoliaSearchService } from '../types/IAlgoliaSearchService';
import { IEntrySearchResultResponse } from '../types/IEntrySearchResultResponse';

export const createAlgoliaSearchService = (
	appId: string,
	readOnlyApiKey: string,
	searchIndex: string,
): IAlgoliaSearchService => {
	const client = algoliasearch(appId, readOnlyApiKey);
	const index = client.initIndex(searchIndex);
	const getSearchResults = async (query: string) => {
		const result = await index.search<IEntrySearchResultResponse>(query, {
			attributesToSnippet: ['title', 'bodyString:6'],
			attributesToRetrieve: ['id', 'slug', 'pageId'],
			attributesToHighlight: [],
			snippetEllipsisText: '…',
		});

		return result.hits;
	};

	return { getSearchResults };
};
