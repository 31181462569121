import log from 'loglevel';
import { IMenuItemEntry } from '@m2/blueprint-contentful';
import { IPathToPageIdMap } from '@m2/blueprint-contentful-routes';
import { IMenuItemNavBar } from '../types/IMenuItemNavBar';
import { getUrlForPage } from '../../../../../../navigation';

export function getMenuItemMapping(
	pathToPageIdMap: IPathToPageIdMap,
	menuItems?: IMenuItemEntry[],
): IMenuItemNavBar[] | undefined {
	if (!menuItems) return undefined;

	const menuItemsWithSlugs = menuItems
		.map((menuItem, index) => {
			const matchedPage = getUrlForPage(
				pathToPageIdMap,
				menuItem.fields.page.fields.id,
			);

			if (!matchedPage) {
				log.warn('Unmatched menu item');
				return undefined;
			}

			return {
				key: `${menuItem.id}-${index}`,
				id: menuItem.id,
				title: menuItem.fields.title,
				url: matchedPage,
				icon: menuItem.fields.icon?.fields.file.url,
				subMenus: getMenuItemMapping(
					pathToPageIdMap,
					menuItem.fields.subMenuItems ?? [],
				),
			};
		})
		.filter((menuItem) => menuItem !== undefined);

	return menuItemsWithSlugs as IMenuItemNavBar[];
}
