import {
	observable,
	computed,
	action,
	IObservableArray,
	makeObservable,
} from 'mobx';
import { IAirline } from '../types';

export class AirlineStore<AirlineType = IAirline> {
	protected _airlines: IObservableArray<AirlineType> = observable<AirlineType>(
		[],
	);

	constructor() {
		makeObservable(this, {
			airlines: computed,
			setAirlines: action,
		});
	}

	public get airlines() {
		return this._airlines;
	}

	public setAirlines(airlines: AirlineType[]) {
		this._airlines.replace(airlines.map((airline) => airline));
	}
}
