export enum Color {
	PrimaryOrange100 = '#ff5000',
	PrimaryOrange800 = '#D93C00',
	PrimaryOrange500 = '#FF5000',
	PrimaryOrange80 = '#ff7333',
	PrimaryLightGray = '#e6e6e6',
	PrimaryLightGray40 = '#ababab',
	PrimaryLightGray50 = '#f3f3f3',
	PrimaryLightGray60 = '#f0f0f0',
	PrimaryOrange60 = '#ff9666',
	PrimaryDarkGray100 = '#585858',
	PrimaryOrange40 = '#ffb999',
	SecondaryPurple100 = '#41395f',
	SecondaryYellow100 = '#f2a900',
	PrimaryOrange20 = '#ffdccc',
	SecondaryBlue = '#739BF1',
	SecondaryBlue100 = '#3eb1c8',
	SecondaryDarkBlue100 = '#5383ec',
	PrimaryDarkGray90 = 'rgb(114, 114, 114)',
	PrimaryDarkGray80 = '#797979',
	PrimaryDarkGray40 = '#bcbcbc',
	PrimaryDarkGray60 = '#9b9b9b',
	SecondaryPurple80 = '#67617f',
	PrimaryDarkGray20 = '#dedede',
	SecondaryPurple60 = '#8d889f',
	SecondaryYellow80 = '#f5ba33',
	SecondaryPurple40 = '#b3b0bf',
	SecondaryYellow60 = '#f7cb66',
	SecondaryYellow50 = '#f8da7f',
	SecondaryPurple20 = '#d9d7df',
	SecondaryYellow20 = '#fceecc',
	SecondaryBlue80 = '#65c1d3',
	SecondaryBlue60 = '#8bd0de',
	SecondaryBlue40 = '#b2e0e9',
	SecondaryBlue20 = '#d9eff4',
	PrimaryWhite = '#ffffff',
	SecondaryGreen100 = 'rgb(38, 144, 69)',
	SecondaryGreen90 = 'rgb(0, 157, 48)',
	SecondaryGreen80 = '#52a66a',
	SecondaryGreen30 = '#47b857',
	SecondaryGreen20 = '#d4e9da',
	SecondaryGreen60 = '#7dbc8f',
	SecondaryGreen40 = '#a8d2b4',
	SecondaryYellow40 = '#fadd99',
	PrimaryRed40 = '#fbaba8',
	PrimaryRed100 = '#fb2720',
	PrimaryRed700 = '#DB001C',
	SecondaryRed100 = '#E10B04',
	PrimaryBlack = '#000000',
	PrimaryBlack70 = '#000000b2',
	PrimaryGreen100 = '#007A18',
	Neutral500 = '#A7A7A7',
	Neutral150 = '#E8EAED',
	SuccessGreen = '#009D30',
	UtilityG200 = '#98D59D',
	UtilityR300 = '#ED6868',
	UtilityO600 = '#F2A900',
}
