import { FirebaseRemoteConfigBoolean } from '@m2/blueprint-firebase';

export const remoteConfigSchema = Object.freeze({
	chatbot_enabled: FirebaseRemoteConfigBoolean,
	security_enabled: FirebaseRemoteConfigBoolean,
	unified_search_enabled: FirebaseRemoteConfigBoolean,
	shuttle_tracking_qr_enabled: FirebaseRemoteConfigBoolean,
	poi_open_status_enabled: FirebaseRemoteConfigBoolean,
});

export type IRemoteConfigSchema = typeof remoteConfigSchema;
