import { IPoi } from '../types/IPoi';

export function itemsQueryFilter(query: string | null, poi: IPoi) {
	if (query === '' || query === null) return true;

	const lowerCaseQuery = query.toLowerCase();

	return (
		poi.name.toLowerCase().includes(lowerCaseQuery) ||
		poi.displayTags?.some((tag) =>
			tag.toLowerCase().includes(lowerCaseQuery),
		) ||
		poi.tags?.some((tag) => tag.toLowerCase().includes(lowerCaseQuery))
	);
}
