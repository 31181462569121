import { useCallback, useState } from 'react';
import { useScrollFunction } from './useScrollFunction';

export interface IScrollPosition {
	total: number;
	current: number;
}

const HEADER_HEIGHT = 100;

export function useScrollPosition() {
	const [scrollPosition, setScrollPosition] = useState<IScrollPosition>({
		total: HEADER_HEIGHT,
		current: 0,
	});

	const scrollFunction = useCallback(() => {
		if (document.scrollingElement) {
			setScrollPosition({
				total:
					document.scrollingElement.scrollHeight -
					document.scrollingElement.clientHeight -
					HEADER_HEIGHT,
				current: document.scrollingElement.scrollTop,
			});
		}
	}, [setScrollPosition]);

	useScrollFunction(scrollFunction);

	return scrollPosition;
}
