import { contentful } from '../../contentful/client';
import { AIRPORT_TYPE_ID } from '../../contentful/constants/contentTypes';
import { IAirportEntry } from '../../contentful/types';
import { IAirport } from '../types';

export async function getAirports(): Promise<IAirport[]> {
	const airports = await contentful()
		.entry<IAirportEntry>(AIRPORT_TYPE_ID)
		.getAll();

	return airports.map((airport) => airport.fields);
}
