import { getPublicConfig } from '../../config';
import { gtag } from './gtag';

export function enableTracking() {
	const { legacyTrackingId, trackingId } = getPublicConfig();

	if (legacyTrackingId) {
		gtag('config', legacyTrackingId);
	}

	if (trackingId) {
		gtag('config', trackingId);
	}
}
