import { IPoi } from '../../../../../../packages/locuslabs-sdk/dist';
import { FilterCategory } from '../types/FilterCategory';

export function getFilterCategories(poi: IPoi): FilterCategory[] {
	const keywordNames = poi.keywords.map((keyword) => keyword.name);
	const normalizedKeywords = keywordNames.map((name) =>
		name.toLowerCase().replace(/\s+/g, ''),
	);

	const categories: FilterCategory[] = [];

	normalizedKeywords.forEach((keyword) => {
		if (keyword === 'fwishop') {
			categories.push(FilterCategory.Shop);
		}
		if (keyword === 'fwieat') {
			categories.push(FilterCategory.Dine);
		}
		if (keyword === 'fwiservice') {
			categories.push(FilterCategory.Service);
		}
	});

	return categories;
}
