import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useThrottledState = <T>(
	delay: number,
	initialState: T,
): [T, Dispatch<SetStateAction<T>>] => {
	const [state, setState] = useState<T>(initialState);
	const [throttledState, setThrottledState] = useState<T>(initialState);

	useEffect(() => {
		const timer = setTimeout(() => setState(throttledState), delay);
		return () => {
			clearTimeout(timer);
		};
	}, [throttledState]);

	return [state, setThrottledState];
};
