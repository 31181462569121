import { MutableRefObject, useState } from 'react';
import { useScrollFunction } from './useScrollFunction';

export function useIsScrolledDown(
	headerRef: MutableRefObject<HTMLDivElement | null>,
) {
	const [isScrolledDown, setIsScrolledDown] = useState<boolean>(false);

	const scrollFunction = () => {
		if (headerRef.current) {
			const viewportOffset = headerRef.current.getBoundingClientRect();
			if (viewportOffset.top === 0 && window.scrollY > 0 && !isScrolledDown) {
				setIsScrolledDown(true);
			} else if (
				viewportOffset.top >= 0 &&
				window.scrollY === 0 &&
				isScrolledDown
			) {
				setIsScrolledDown(false);
			}
		}
	};

	scrollFunction();

	useScrollFunction(scrollFunction);

	return isScrolledDown;
}
