import React from 'react';
import styled from '@emotion/styled';
import {
	ArrowBackIcon,
	ChevronRightIcon,
	LinkText,
	StyledIconLink,
	StyledLinkText,
} from '@m2/blueprint-ui';
import { IMenuItemNavBar } from '../types/IMenuItemNavBar';
import { DropDownMenuItemMobile } from './DropDownMenuItemMobile';
import { MobileMenuOverlay } from './MobileMenuOverlay';

interface IDropDownMenuListItemMobileProps {
	menuItem: IMenuItemNavBar;
	isTransparent: boolean;
	layer: number;
	onClickLink: () => void;
	onClickBack: () => void;
}

export const DropDownMenuListItemMobile = ({
	menuItem,
	isTransparent,
	layer,
	onClickLink,
	onClickBack,
}: IDropDownMenuListItemMobileProps) => {
	if (!menuItem.subMenus?.length) return null;

	return (
		<StyledListWrapper>
			<StyledSubMenu index={layer}>
				<StyledSubmenuTitle>
					<ArrowBackIcon
						variation="brand"
						role="button"
						aria-label="Back"
						onClick={(event) => {
							event.stopPropagation();
							onClickBack();
						}}
					/>
					<StyleCustomIconLinkHeader href={menuItem.url} onClick={onClickLink}>
						<LinkText>{menuItem.title}</LinkText>
						<ChevronRightIcon variation="brand" />
					</StyleCustomIconLinkHeader>
				</StyledSubmenuTitle>

				<DropDownMenuItemMobile
					subMenus={menuItem.subMenus}
					isTransparent={isTransparent}
					onClickLink={onClickLink}
				/>
			</StyledSubMenu>
		</StyledListWrapper>
	);
};

const StyledSubmenuTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-bottom: 8px;
	border-bottom: 1px solid #e6e6e6;
	font-weight: bold;
	font-weight: 18px;
`;

const StyleCustomIconLinkHeader = styled(StyledIconLink)`
	width: 100%;

	${StyledLinkText} {
		flex-grow: 1;
		text-align: center;
	}

	${ChevronRightIcon} {
		height: 24px;
	}
`;

const StyledListWrapper = styled.div``;

const StyledSubMenu = styled(MobileMenuOverlay)<{ index: number }>`
	display: flex;
	flex-direction: column;
	transition: all 0.35s ease-in-out;
	opacity: 1;
	height: 100vh;
	overflow: hidden;
	z-index: ${({ index }) => index + 5};
`;
