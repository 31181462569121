/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import styled from '@emotion/styled';
import {
	ChevronUpIcon,
	ContentWrap,
	FixedGrid,
	FixedGridItem,
	IFixedGridProps,
	ITheme,
	IUrlLinkItem,
	Responsive,
	StyledArrowLinkButton,
	TagButton,
	themed,
} from '@m2/blueprint-ui';
import {
	FooterAppLinks,
	FooterFinePrint,
	FooterLinkSections,
	FooterSocialLinks,
	getFooterLinks,
	StyledFooterLinkSection,
} from '.';
import { Color } from '../../../styles/Color';
import { useCommonData } from '../../../common-data/useCommonData';
import { DigicertSiteSeal } from '../digicert/DigicertSiteSeal';

export const StyledFooter = styled.footer<{}, ITheme>`
	background: ${themed(({ background }) => background.light)};
	color: ${themed(({ color }) => color.text.default)};
	padding: 64px 0;
	margin-top: 40px;
	overflow: hidden;
	position: relative;
	bottom: 0;
	width: auto;

	h4 {
		color: ${themed(({ color }) => color.text.default)};
	}

	ul {
	}

	li {
		margin-bottom: 10px;
	}

	hr {
		margin: 40px 0;
		background: ${Color.PrimaryDarkGray40};

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			display: none;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		${StyledArrowLinkButton} {
			font-size: 16px;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		padding: 20px 0;

		hr {
			display: none;
		}
	}
`;

export interface IFooterLinkSection {
	key: string;
	title: string;
	links: IUrlLinkItem[];
}

export type IFooterLinkSections = [
	IFooterLinkSection,
	IFooterLinkSection,
	IFooterLinkSection,
];

export interface IFooterProps {
	linkGridColumns?: IFixedGridProps['columns'];
}

export const Footer = ({ linkGridColumns }: IFooterProps) => {
	const { footer, pathToPageIdMap } = useCommonData();

	if (!footer) {
		return null;
	}

	const { linkSections, appLinks, socialLinks, finePrintLinks } =
		getFooterLinks(footer, pathToPageIdMap);

	return (
		<StyledFooter>
			<StyledScrollButton>
				<TagButton onClick={() => window.scrollTo(0, 0)}>
					<>
						<strong>Go to top</strong>
						<ChevronUpIcon variation="inverted" />
					</>
				</TagButton>
			</StyledScrollButton>
			<ContentWrap>
				<FixedGrid
					columns={linkGridColumns!}
					itemSelector={[StyledFooterLinkSection, FixedGridItem]}
				>
					<FooterLinkSections linkSections={linkSections} />
				</FixedGrid>
				<hr />
				<FixedGrid columns={linkGridColumns!}>
					<FooterAppLinks {...appLinks} />
					<FooterSocialLinks {...socialLinks} />
					<FixedGridItem>
						<DigicertSiteSeal />
					</FixedGridItem>
				</FixedGrid>
				<FooterFinePrint {...finePrintLinks} />
			</ContentWrap>
		</StyledFooter>
	);
};

Footer.defaultProps = {
	linkGridColumns: [3, { tablet: 2, mobileXL: 1 }],
};

const StyledScrollButton = styled.div<{}, ITheme>`
	position: relative;

	${TagButton} {
		position: absolute;
		right: -20px;
		top: 20px;
		background-color: ${Color.SecondaryBlue100};
		border: 0;
		box-sizing: border-box;
		color: white;
		height: 80px;
		width: 160px;

		&:hover {
			box-shadow: inset 0 0 0 1px transparent;
		}

		@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
			top: 60px;
		}

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			top: 570px;
			height: 48px;
		}
	}
`;
