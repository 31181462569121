import { PoiOpenStatus } from '@m2/blueprint-locuslabs-sdk';
import { IPoi } from '../types/IPoi';
import { itemsCategoryFilter } from './itemsCategoryFilter';
import { itemsQueryFilter } from './itemsQueryFilter';
import { itemsTerminalFilter } from './itemsTerminalFilter';
import { itemsTagFilter } from './itemsTagFilter';
import { IItemFilterOptions } from '../types/IItemFilterOptions';

export function getFilteredPoiItems(
	pois: IPoi[],
	{
		category,
		terminal,
		tag,
		userQuery,
		nowOpen,
		mobileOrdering,
	}: IItemFilterOptions,
) {
	let filteredPois = pois
		.filter((poi) => itemsCategoryFilter(category, poi))
		.filter((poi) => itemsTagFilter(tag, poi))
		.filter((poi) => itemsTerminalFilter(terminal, poi))
		.filter((poi) => itemsQueryFilter(userQuery, poi));

	if (nowOpen) {
		filteredPois = filteredPois.filter(
			(poi) => poi.openStatus !== PoiOpenStatus.Closed,
		);
	}

	if (mobileOrdering) {
		filteredPois = filteredPois.filter(
			(poi) => poi.additionalAttributes.grabUrl,
		);
	}

	return filteredPois;
}
