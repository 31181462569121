import {
	getLocalStorageItem,
	setLocalStorageItem,
} from '../../../utils/localStorage';
import { IUnifiedSearchResult } from '../models/IUnifiedSearchResult';

export type PersistRecentSearches = typeof persistRecentSearches;

const RECENT_SEARCHES_KEY = 'recent_searches';
const saveRecentSearchesToLocalStorage = (value: IUnifiedSearchResult[]) =>
	setLocalStorageItem(RECENT_SEARCHES_KEY, JSON.stringify(value));

const getRecentSearchesFromLocalStorage = () => {
	const recentSearches = getLocalStorageItem(RECENT_SEARCHES_KEY);

	if (!recentSearches) {
		return [];
	}

	return JSON.parse(recentSearches);
};

export const persistRecentSearches = {
	saveRecentSearchesToLocalStorage,
	getRecentSearchesFromLocalStorage,
};
