import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
	Responsive,
	FixedGridItem,
	ChevronDownIcon,
	ChevronUpIcon,
} from '@m2/blueprint-ui';
import { IFooterLinkSection } from './Footer';
import { FooterSectionHeader } from './FooterSectionHeader';
import { FooterLinkList } from './FooterLinkList';
import { Color } from '../../../styles/Color';

export const StyledFooterLinkSection = styled(FixedGridItem)`
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		border-bottom: 1px solid ${Color.PrimaryDarkGray40};
		padding-bottom: 16px;

		&:last-of-type {
			margin-bottom: 40px;
		}
	}
`;

interface IFooterSectionHeaderTitleProps {
	isOpen: boolean;
}

const FooterSectionHeaderTitle = styled(
	FooterSectionHeader,
)<IFooterSectionHeaderTitleProps>`
	svg {
		display: none;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0;

		svg {
			display: block;
		}
	}
`;

export const FooterLinkSection = ({ title, links }: IFooterLinkSection) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<StyledFooterLinkSection>
			<FooterSectionHeaderTitle
				isOpen={isOpen}
				onClick={() => setIsOpen(!isOpen)}
			>
				<span>{title}</span>
				{isOpen ? (
					<ChevronUpIcon variation="brand" />
				) : (
					<ChevronDownIcon variation="brand" />
				)}
			</FooterSectionHeaderTitle>
			<FooterLinkList links={links} isOpen={isOpen} />
		</StyledFooterLinkSection>
	);
};
