import React from 'react';
import styled from '@emotion/styled';

import { ChevronRightIcon, LinkText, StyledIconLink } from '@m2/blueprint-ui';
import { IMenuItemNavBar } from '../types/IMenuItemNavBar';

interface IDropDownMenuRow {
	menuItem: IMenuItemNavBar;
	onClickLink: () => void;
}

export const DropDownMenuRow = ({
	menuItem,
	onClickLink,
}: IDropDownMenuRow) => {
	const TextRow: JSX.Element = (
		<StyledTextRow>
			{menuItem.icon && (
				<StyledImage src={menuItem.icon} alt={`${menuItem.title}`} />
			)}
			<StyledLinkText hasIcon={!!menuItem.icon}>
				{menuItem.title}
			</StyledLinkText>
		</StyledTextRow>
	);

	return (
		<>
			{menuItem.subMenus.length === 0 ? (
				<StyledCustomLink href={menuItem.url} onClick={onClickLink}>
					{TextRow}
				</StyledCustomLink>
			) : (
				<>{TextRow}</>
			)}
			<StyledChevronRightIcon variation="brand" className="chevron" />
		</>
	);
};

const StyledImage = styled.img`
	width: 25px;
	height: 25px;
	margin-right: 16px;
`;

const StyledTextRow = styled.div`
	width: calc(100% - 24px);
`;

const StyledCustomLink = styled(StyledIconLink)`
	width: 100%;
	:after {
		display: none;
	}
`;

const StyledLinkText = styled(LinkText)<{ hasIcon: boolean }>`
	max-width: ${({ hasIcon }) => (hasIcon ? `calc(100% - 41px)` : '100%')};
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
	flex-shrink: 0;
	width: 24px;
`;
