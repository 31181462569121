import { ChevronRightIcon, ICustomTheme } from '@m2/blueprint-ui';
import { FONT_FAMILY_NEUE_HAAS_UNICA } from './Fonts';
import { Color } from './Color';

export const theme: ICustomTheme = {
	highlight: {
		background: Color.PrimaryLightGray,
		padding: {
			default: '80px 60px',
			tablet: '',
			mobileXL: '40px',
			mobile: '40px 20px',
		},
		elevated: {
			boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.1)',
			hover: {
				boxShadow: '0 20px 30px 0 rgba(0, 0, 0, 0.1)',
			},
		},
	},
	alertBanner: {
		background: Color.SecondaryYellow100,
	},
	layout: {
		page: {
			padding: '0',
		},
	},
	dropdownList: {
		option: {
			highlighted: {
				background: Color.PrimaryLightGray,
				color: Color.PrimaryDarkGray100,
			},
			secondaryStyle: {
				default: {
					background: '#d3d3df',
					color: Color.PrimaryBlack,
				},
				highlighted: {
					background: Color.SecondaryPurple60,
					color: Color.PrimaryWhite,
				},
			},
		},
	},
	grid: {
		gutter: {
			horizontal: {
				default: '40px',
			},
		},
	},
	link: {
		ArrowLinkIcon: ChevronRightIcon,
	},
	quickLink: {
		item: {
			border: {
				width: '0px',
			},
			padding: {
				desktop: '25px 0',
				mobile: '15px 0',
			},
		},
	},
	contact: {
		card: {
			iconColor: Color.PrimaryDarkGray100,
		},
	},
	card: {
		boxShadow: '0',
		contentMargin: '8px',
		fullyClickable: {
			boxShadow: '0',
			contentMargin: '8px',
			hover: {
				boxShadow: '0',
			},
		},
	},
	select: {
		focus: {
			boxShadow: `0 0 0 0 rgb(0 0 0), inset 0 -3px 0 0 ${Color.PrimaryOrange100}`,
		},
		main: {
			borderRadius: '4px',
		},
	},
	search: {
		button: {
			background: Color.PrimaryOrange100,
			borderRadius: '4px',
		},
	},
	background: {
		default: Color.PrimaryWhite,
		light: Color.PrimaryLightGray,
		highlight: Color.PrimaryOrange100,
	},
	button: {
		radius: '4px',
		primary: {
			border: `solid 2px ${Color.PrimaryOrange100}`,
			background: Color.PrimaryOrange100,
			font: {
				size: '16px',
				weight: 'bold',
				fontFamily: `${FONT_FAMILY_NEUE_HAAS_UNICA}, sans-serif`,
			},
			hover: {
				background: Color.PrimaryOrange80,
				border: `solid 2px ${Color.PrimaryOrange80}`,
			},
			inverted: {
				hover: {
					font: {
						color: Color.PrimaryOrange80,
					},
				},
				background: Color.PrimaryWhite,
				border: `2px solid ${Color.PrimaryWhite}`,
				font: {
					color: Color.PrimaryOrange100,
					size: '16px',
					weight: 'bold',
					fontFamily: `${FONT_FAMILY_NEUE_HAAS_UNICA}, sans-serif`,
				},
				active: {
					boxShadow: '0 2px 0 0 #efeff4',
					transform: 'translateY(1px)',
					font: {
						color: Color.PrimaryOrange100,
					},
				},
				disabled: {
					opacity: '1',
					border: '0px',
					background: Color.PrimaryOrange60,
					font: {
						color: Color.PrimaryWhite,
					},
				},
			},
		},
		secondary: {
			border: `solid 2px ${Color.PrimaryOrange100}`,
			font: {
				color: Color.PrimaryOrange100,
				size: '16px',
				weight: 'bold',
				fontFamily: `${FONT_FAMILY_NEUE_HAAS_UNICA}, sans-serif`,
			},
			hover: {
				border: `solid 2px ${Color.PrimaryOrange80}`,
				font: {
					color: Color.PrimaryOrange100,
				},
			},
			inverted: {
				border: `2px solid ${Color.PrimaryWhite}`,
				background: Color.PrimaryOrange100,
				font: {
					color: Color.PrimaryWhite,

					weight: 'bold',
					fontFamily: `${FONT_FAMILY_NEUE_HAAS_UNICA}, sans-serif`,
				},
				hover: {
					background: 'none',
					font: {
						color: Color.PrimaryWhite,
					},
				},
				disabled: {
					opacity: '0.6',
				},
			},
		},
	},
	color: {
		brand: Color.PrimaryOrange100,
		background: {
			default: Color.PrimaryWhite,
			light: Color.PrimaryLightGray,
		},
		highlight: Color.PrimaryOrange100,
		text: {
			light: Color.PrimaryDarkGray60,
			default: Color.PrimaryDarkGray100,
			inverted: Color.PrimaryWhite,
		},
	},
	border: {
		default: `1px solid ${Color.PrimaryLightGray}`,
	},

	typography: {
		body: {
			fontFamily: `${FONT_FAMILY_NEUE_HAAS_UNICA}, sans-serif`,
			size: '16px',
			lineHeight: '26px',
			color: Color.PrimaryDarkGray100,
		},
		header: {
			h1: {
				size: '40px',
				color: Color.PrimaryDarkGray100,
			},
			h2: {
				size: '32px',
				color: Color.PrimaryDarkGray100,
			},
			h3: {
				size: '24px',
				color: Color.PrimaryDarkGray100,
			},
			h4: {
				size: '18px',
				color: Color.PrimaryDarkGray100,
			},
		},
		link: {
			textDecoration: 'none',
			hover: {
				textDecoration: 'underline',
				color: Color.PrimaryOrange100,
			},
			color: Color.PrimaryOrange100,
		},
		input: {
			size: '16px',
		},
		small: {
			size: '12px',
		},
	},
	input: {
		boxShadow: 'none',
		borderRadius: '4px',
		error: {
			borderRadius: '4px',
		},
		warning: {
			borderRadius: '4px',
		},
		focus: {
			outline: 'none',
			borderRadius: '4px',
			boxShadow: `0 0 0 0 rgb(0 0 0), inset 0 -3px 0 0 ${Color.PrimaryOrange100}`,
		},
		icon: {
			background: Color.PrimaryOrange100,
		},
		placeholder: {
			color: Color.PrimaryDarkGray100,
			focussed: {
				color: Color.PrimaryOrange100,
			},
		},
	},
	blockquote: {
		boxShadow: `inset 3px 0 0 0 ${Color.SecondaryBlue100}`,
		paragraph: { color: Color.PrimaryDarkGray100, size: '16px' },
	},
	callToAction: {
		background: {
			inverted: Color.PrimaryOrange100,
		},
	},
	accordion: {
		title: {
			padding: '20px 24px',
		},
		content: {
			padding: '20px 24px',
		},
	},
	chip: {
		background: {
			default: Color.SecondaryYellow100,
			light: Color.PrimaryLightGray60,
			highlight: Color.SecondaryBlue100,
			brand: Color.PrimaryOrange100,
		},
	},
};
