import styled from '@emotion/styled';
import { Responsive } from '../../../../../../../../../packages/ui/dist';
import { NAVIGATION_PADDING_M, NAVIGATION_PADDING_S } from '../../constants';

export const MobileMenuOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	padding-top: 88px;
	padding-left: 32px;
	padding-right: 32px;
	background-color: white;

	@media (max-width: 1100px) {
		padding-left: ${NAVIGATION_PADDING_M};
		padding-right: ${NAVIGATION_PADDING_M};
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding-left: ${NAVIGATION_PADDING_S};
		padding-right: ${NAVIGATION_PADDING_S};
	}
`;
