import { observable, action, makeObservable } from 'mobx';
import { IRequestOptions, RemoteDataStore } from '@m2/blueprint-utils';
import log from 'loglevel';

import { ISkydiveWeatherResponse } from '../../../../skydive/types/ISkydiveWeatherResponse';
import { Skydive } from '../../../../skydive';
import { ISkydiveWeather } from '../../../../skydive/types/ISkydiveWeather';
import { IWeatherParams } from '../../../../skydive/services/WeatherService';

// TODO create seperate weatherstore feature
export class WeatherStore extends RemoteDataStore<
	IWeatherParams,
	ISkydiveWeatherResponse
> {
	public weather: ISkydiveWeather | undefined;

	protected readonly api: Skydive;

	constructor(api: Skydive) {
		super();

		makeObservable<WeatherStore, 'handleResponse'>(this, {
			weather: observable,
			handleResponse: action,
			clear: action,
		});

		this.api = api;
	}

	protected async performRequest(
		params: IWeatherParams,
		options: IRequestOptions,
	) {
		return this.api.weather().get(params, options);
	}

	protected handleResponse(response: ISkydiveWeatherResponse) {
		const weather = response.data;
		log.info('Weather loaded', weather);

		this.weather = weather;
	}

	public clear() {
		this.weather = undefined;
	}
}
