import { locusMapsClient } from '@m2/blueprint-locuslabs-sdk';
import { createGetAllPois } from './getAllPois';

export const getAllPois = async () => {
	const sdkClient = await locusMapsClient('dfw', 'A1GKNNFXZEQW1J', true);

	if (sdkClient instanceof Error) {
		throw new Error('Something went wrong');
	}

	return createGetAllPois(sdkClient);
};
