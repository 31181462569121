import React, { Component } from 'react';
import { ErrorMessage } from './ErrorMessage';
import { logError } from '../../../utils';

export class ErrorBoundary extends Component<{}> {
	public state = { hasError: false };

	public static getDerivedStateFromError() {
		return { hasError: true };
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public componentDidCatch(error: Error, info: any) {
		logError(error, info);
	}

	public render() {
		const { hasError } = this.state;
		const { children } = this.props;

		if (!hasError) {
			return children;
		}

		return <ErrorMessage />;
	}
}
