import React from 'react';

import { UnifiedSearchNothingFoundViewModel } from './UnifiedSearchNothingFoundViewModel';
import { NoResultsMessage } from '../../components/common/error';

export interface IUnifiedSearchNothingFoundProps {
	viewModel: UnifiedSearchNothingFoundViewModel;
}

export const UnifiedSearchNothingFound = ({
	viewModel: { title, imageSource, imageAlt, body },
}: IUnifiedSearchNothingFoundProps) => (
	<NoResultsMessage
		title={title}
		imageSource={imageSource}
		imageAlt={imageAlt}
		body={body}
	/>
);
