import { isPreviewMode } from './isPreviewMode';

/**
 * Check if the cookie has previewData defined (by previewApiRoute) and check environment variable is = 1.
 * @param isPreview isPreview - come from appContext.Router defined by setPreviewData()
 * @returns Boolean
 */
export function isPreviewModeDisabled(isPreview: boolean): boolean {
	return !isPreview && isPreviewMode();
}
