import React, { FC } from 'react';
import { I18nContext } from './I18nContext';
import { initI18nWithTranslations } from './initI18n';
import { Translations } from './Translations';

export const I18nProvider: FC<{
	locale: string;
	translations: Translations;
}> = ({ children, locale, translations }) => {
	const i18n = initI18nWithTranslations(locale, translations);

	return <I18nContext.Provider value={i18n}>{children}</I18nContext.Provider>;
};
