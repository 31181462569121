import Cookies from 'js-cookie';
import { makeAutoObservable } from 'mobx';

interface ICreateAccountTooltip {
	isAccountCookieSet: boolean;
	setAccountCookie(): void;
}

const COOKIE_ACCOUNT_KEY = 'cookie-account';
const COOKIE_ACCOUNT_VALUE = '1';

// 6 months
const COOKIE_ACCOUNT_EXPIRES = 30 * 6;

export const createAccountTooltipStore = (): ICreateAccountTooltip => {
	const privateStore = makeAutoObservable<ICreateAccountTooltip>({
		isAccountCookieSet:
			Cookies.get(COOKIE_ACCOUNT_KEY) === COOKIE_ACCOUNT_VALUE,
		setAccountCookie() {
			Cookies.set(COOKIE_ACCOUNT_KEY, COOKIE_ACCOUNT_VALUE, {
				expires: COOKIE_ACCOUNT_EXPIRES,
			});
			this.isAccountCookieSet = true;
		},
	});

	return {
		get isAccountCookieSet() {
			return privateStore.isAccountCookieSet;
		},
		setAccountCookie() {
			return privateStore.setAccountCookie();
		},
	};
};
