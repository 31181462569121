import { ISkydiveWaitTimesResponse } from '../../../skydive/types/ISkydiveWaitTimesResponse';
import { IWaitTime } from '../stores/waitTimesStore';
import { Crowdedness } from '../../../checkpoints/types/Crowdedness';

export const mapWaitBusyness = (waitSeconds: number): Crowdedness => {
	if (waitSeconds === 0) return Crowdedness.Quiet;
	const waitMinutes = Math.ceil(waitSeconds / 60);
	if (waitMinutes > 0 && waitMinutes <= 10) return Crowdedness.Quiet;
	if (waitMinutes > 10 && waitMinutes <= 20) return Crowdedness.Crowded;
	if (waitMinutes > 20) return Crowdedness.Full;
	return Crowdedness.None;
};

export const mapWaitMinutes = (waitSeconds: number) => {
	if (waitSeconds === 0) return 0;
	return Math.ceil(waitSeconds / 60);
};

export const mapWaitTimes = (
	waitTimesResponse: ISkydiveWaitTimesResponse,
): IWaitTime[] =>
	waitTimesResponse.data.wait_times.map(
		({ waitSeconds, isOpen, attributes, isDisplayable }) => {
			const isDisplayableValue = waitSeconds !== null ? isDisplayable : false;
			return {
				open: isOpen,
				isDisplayable: isDisplayableValue,
				poiId: attributes.poi_id ?? null,
				waitMinutes:
					waitSeconds !== null
						? mapWaitMinutes(waitSeconds)
						: mapWaitMinutes(0),
				crowdedness:
					waitSeconds !== null
						? mapWaitBusyness(waitSeconds)
						: Crowdedness.None,
			};
		},
	);
