import { IAirline } from '../types';
import { AIRLINE_TYPE_ID, contentful, IAirlineEntry } from '../../contentful';

const sortAirlines = (a?: IAirline, b?: IAirline) => {
	const first = a?.airlineName?.toLowerCase();
	const second = b?.airlineName?.toLowerCase();
	if (!first || !second) {
		return 0;
	}
	if (first < second) {
		return -1;
	}
	if (first > second) {
		return 1;
	}
	return 0;
};

export async function getAirlines(): Promise<IAirline[]> {
	const airlines = await contentful()
		.entry<IAirlineEntry>(AIRLINE_TYPE_ID)
		.getAll();

	return airlines.map(mapResponseToAirline).sort(sortAirlines);
}

function mapResponseToAirline({ fields }: IAirlineEntry): IAirline {
	return {
		airlineName: fields.airlineName,
		iata: fields.iata,
		icao: fields.icao,
	};
}
