import noResultsImage from '../../assets/no-unified-search-results.svg?reference';

export type UnifiedSearchNothingFoundViewModel = ReturnType<
	typeof createUnifiedSearchNothingFoundViewModel
>;

export const createUnifiedSearchNothingFoundViewModel = () => ({
	title: 'No results were found.',
	body: 'Check your search terms and try again.',
	imageSource: noResultsImage,
	imageAlt: 'No flights, facilities and information found',
});
