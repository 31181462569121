import { AxiosRequestConfig } from 'axios';
import { IParkingAvailabilityResponse } from '../types/ISkydiveParkingAvailabilityResponse';
import { AbstractService } from './AbstractService';

export class ParkingAvailabilityService extends AbstractService {
	/**
	 * Path of the resource to be used by the service.
	 */
	public static readonly URI_PATH = '/parking/availability/DFW';

	public get(
		requestConfig: AxiosRequestConfig = {
			method: 'GET',
		},
	): Promise<IParkingAvailabilityResponse> {
		const uri = ParkingAvailabilityService.URI_PATH;

		return this.client.request(uri, requestConfig);
	}
}
