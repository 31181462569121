import { parsePhoneNumber } from './parsePhoneNumber';

/**
 * Eg: "213 373 4253"
 */
export const formatNationalPhoneNumber = (input: string) => {
	try {
		return parsePhoneNumber(input).format('NATIONAL', {
			nationalPrefix: false,
		});
	} catch {
		return input;
	}
};
