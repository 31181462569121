import { AxiosRequestConfig } from 'axios';
import { stringify } from 'querystring';
import { AbstractService } from './AbstractService';
import { ISkydiveFlightIdentifier } from '../types/ISkydiveFlightIdentifier';
import { ISkydiveCheckpointRecommendation } from '../types/ISkydiveCheckpointRecommendation';

export class CheckpointRecommendationService extends AbstractService {
	public static readonly URI_PATH = '/checkpoints/recommendation';

	public get(
		flight: ISkydiveFlightIdentifier,
		requestConfig: AxiosRequestConfig = {},
	): Promise<ISkydiveCheckpointRecommendation> {
		const uri = CheckpointRecommendationService.URI_PATH;

		return this.client
			.request<{ data: { checkpoint: ISkydiveCheckpointRecommendation } }>(
				uri,
				{
					method: 'POST',
					data: stringify({ flight_id_list: JSON.stringify([flight]) }),
					...requestConfig,
				},
			)
			.then((response) => response.data.checkpoint);
	}
}
