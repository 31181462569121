import { getBuildConfig } from '../config/getBuildConfig';

interface IContentfulConfig {
	space: string;
	environment?: string;
	accessToken: string;
	previewAccessToken: string;
	previewHost: string;
	host: string;
}

let contentfulConfig: IContentfulConfig | null = null;

export function getContentfulConfig() {
	if (contentfulConfig !== null) {
		return contentfulConfig;
	}

	const {
		contentfulSpace,
		contentfulEnvironment,
		contentfulAccessToken,
		contentfulAccessTokenPreview,
	} = getBuildConfig();

	const accessToken = contentfulAccessToken;
	const previewAccessToken = contentfulAccessTokenPreview;

	const previewHost = 'preview.contentful.com';
	const host = 'cdn.contentful.com';

	contentfulConfig = {
		space: contentfulSpace,
		environment: contentfulEnvironment,
		accessToken,
		previewAccessToken,
		previewHost,
		host,
	};

	return contentfulConfig;
}
