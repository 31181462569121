import { CountryCallingCode, PhoneNumber } from 'libphonenumber-js';
import { CountryCode } from 'libphonenumber-js/min';
import { ICognitoUserAddress } from '../../../common/cognito/types/ICognitoUserInfo';
import { formatNationalPhoneNumber } from '../../../common/utils/formatNationalPhoneNumber';
import { parsePhoneNumber } from '../../../common/utils/parsePhoneNumber';
import { useAuth } from '../../common/context/AuthContext';
import { IUser } from '../domain/models/IUser';

// Use this hook to get the user's formatted data
export const useUser = (): IUser | undefined => {
	const { user } = useAuth();

	if (!user) {
		return undefined;
	}

	const {
		username: id,
		attributes: {
			given_name: firstName,
			family_name: lastName,
			email,
			address,
			phone_number: mobile,
			'custom:marketing-offer': newsletter,
		},
		preferredMFA = 'NOMFA',
	} = user;

	const parsedAddress: ICognitoUserAddress = address ? JSON.parse(address) : {};

	let countryCode: CountryCode | undefined;
	let mobilePrefix: CountryCallingCode | undefined;

	if (mobile) {
		const phoneData: PhoneNumber = parsePhoneNumber(mobile);
		countryCode = phoneData?.country;
		mobilePrefix = phoneData?.countryCallingCode;
	}

	const formattedMobile = mobile
		? formatNationalPhoneNumber(mobile)
		: undefined;

	return {
		id,
		firstName,
		lastName,
		email,
		address: parsedAddress,
		countryCode,
		mobilePrefix,
		mobile: formattedMobile,
		newsletter,
		preferredMFA,
	};
};
