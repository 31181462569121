import { Contentful } from '@m2/blueprint-contentful';
import { isPreviewMode } from '../config/utils/isPreviewMode';
import { isProductionBuild } from '../config/utils';
import { getContentfulConfig } from './getContentfulConfig';

let client: Contentful;

export const contentful = () => {
	if (client) {
		return client;
	}

	const isPreview = isPreviewMode();

	const { host, previewHost, accessToken, previewAccessToken, ...config } =
		getContentfulConfig();

	client = new Contentful({
		...config,
		accessToken: isPreview ? previewAccessToken : accessToken,
		host: isPreview ? previewHost : host,
		hasCache: true,
		maxCacheAgeInSeconds: isProductionBuild() ? undefined : 10,
	});

	return client;
};
