import { contentful, POPULAR_SEARCHES } from '../../contentful';
import { IPopularUnifiedSearchEntry } from '../../contentful/types/IPopularUnifiedSearchEntry';
import { IPopularUnifiedSearch } from '../types/IPopularUnifiedSearch';

export async function getPopularItems(): Promise<IPopularUnifiedSearch> {
	const unifiedSearch = await contentful()
		.entry<IPopularUnifiedSearchEntry>(POPULAR_SEARCHES)
		.getFirst();

	return unifiedSearch?.fields ?? { popularSearches: [] };
}
