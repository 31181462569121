import { IShuttleServiceType } from '../../live-shuttle-tracking/tracker/domain/types/IShuttleServiceType';

export function getBaseUriPath(serviceType: IShuttleServiceType): string {
	switch (serviceType) {
		case IShuttleServiceType.TerminalLink:
			return '/bus-stops/transfer-link';
		case IShuttleServiceType.RemoteShuttle:
			return 'bus-stops/remote';
		case IShuttleServiceType.ExpressShuttle:
			return '/bus-stops/express';
		default:
			throw new Error(`Unsupported shuttle service type: ${serviceType}`);
	}
}
