import log from 'loglevel';
import { getPathToPageIdMap } from '@m2/blueprint-contentful-routes';
import {
	contentful,
	FOOTER_TYPE_ID,
	NEWS_ALERT_TYPE_ID,
	PARKING_WIDGET,
	SHUTTLE_TRACKING_MESSAGES_ID,
} from '../contentful';
import { IFooterEntry } from '../contentful/types/IFooterEntry';
import { defaultLocale } from '../i18n/Locale';
import { ICommonData } from './ICommonData';
import { INewsAlertEntry } from '../contentful/types/INewsAlertEntry';
import { customPagePathToPageIdMap } from '../contentful/routes/customPagePathToPageIdMap';
import { getAirlines } from '../airlines/services/getAirlines';
import { getAirports } from '../airports/services/getAirports';
import { IParkingWidgetEntry } from '../contentful/types/IParkingWidgetEntry';
import { IShuttleTrackingMessageEntry } from '../contentful/types/IShuttleTrackingMessageEntry';
import { getTranslationsData } from '../i18n/translate/getTranslationsData';
import { getPopularItems } from '../unified-search/services';

export async function getCommonData(
	locale = defaultLocale,
): Promise<ICommonData> {
	log.info('Fetch common data');

	const siteSections = await contentful()
		.siteSection()
		.getAllForLocaleWithMenuItems(locale.code, { include: 4 });

	const pathToPageIdMap = await getPathToPageIdMap(
		contentful(),
		locale,
		customPagePathToPageIdMap,
	);

	const [airlines, airports] = await Promise.all([
		getAirlines(),
		getAirports(),
	]);

	const newsAlerts = await contentful()
		.entry<INewsAlertEntry>(NEWS_ALERT_TYPE_ID)
		.getAll();

	const footer = await contentful()
		.entry<IFooterEntry>(FOOTER_TYPE_ID)
		.getFirst({ include: 3 });

	const parkingWidget = await contentful()
		.entry<IParkingWidgetEntry>(PARKING_WIDGET)
		.getFirst({ include: 3 });

	const shuttleTrackingMessages = await contentful()
		.entry<IShuttleTrackingMessageEntry>(SHUTTLE_TRACKING_MESSAGES_ID)
		.getAll();

	const translations = await getTranslationsData();

	const unifiedSearches = await getPopularItems();

	return {
		pathToPageIdMap,
		siteSections,
		newsAlerts,
		footer,
		airlines,
		airports,
		parkingWidget,
		shuttleTrackingMessages,
		translations,
		unifiedSearches,
	};
}
