import { ISiteSectionEntry } from '@m2/blueprint-contentful';

export function getCurrentSiteSection(
	allSiteSections: ISiteSectionEntry[],
	router: { asPath: string },
) {
	const siteSectionsWithSlug = allSiteSections.filter(
		(item) => item.fields.slug,
	);
	const selectedSiteSection = siteSectionsWithSlug.find((item) =>
		router.asPath.substring(1).startsWith(item.fields.slug as string),
	);
	if (selectedSiteSection) {
		return selectedSiteSection;
	}
	const siteSectionsWithoutSlug = allSiteSections.filter(
		(item) => !item.fields.slug,
	);

	return siteSectionsWithoutSlug[0];
}
