import { Categories } from '../types/Categories';
import { FilterCategory } from '../types/FilterCategory';
import { IPoi } from '../types/IPoi';

export function itemsCategoryFilter(category: string, poi: IPoi) {
	if (category === Categories.All) {
		return true;
	}

	if (category === Categories.Shops) {
		return poi.filterCategories.includes(FilterCategory.Shop);
	}
	if (category === Categories.Dine) {
		return poi.filterCategories.includes(FilterCategory.Dine);
	}
	if (category === Categories.Services) {
		return poi.filterCategories.includes(FilterCategory.Service);
	}

	return false;
}
