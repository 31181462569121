import { AirlineStore } from '../../../../airlines/stores';
import { AirportStore } from '../../../../airports/stores';
import { createFlightEnrichers } from '../use-cases/createFlightEnrichers';
import { FlightEnrichStore } from './FlightEnrichStore';
import { IFlight } from '../types/IFlight';
import { IEnrichedFlightProps } from '../types/IEnrichedFlight';

export function createFlightEnrichStore(
	airlineStore: AirlineStore,
	airportStore: AirportStore,
) {
	const enrichers = createFlightEnrichers(airlineStore, airportStore);

	return new FlightEnrichStore<IFlight, IEnrichedFlightProps>(
		enrichers.airlineAndAirportEnricher,
	);
}
