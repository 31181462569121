import noResultsImage from '../../assets/no-unified-search-results.svg?reference';
import { TranslationKey } from '../../i18n/translate/TranslationKey';

export type UnifiedSearchEmptyStateViewModel = ReturnType<
	typeof createUnifiedSearchEmptyStateViewModel
>;

export const createUnifiedSearchEmptyStateViewModel = () => ({
	title: 'unified_search_title' as TranslationKey,
	body: 'unified_search_description' as TranslationKey,
	imageSource: noResultsImage,
});
