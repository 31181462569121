import React from 'react';
import { GlobalStyles as BaseGlobalStyles } from '@m2/blueprint-ui';
import { Fonts } from './Fonts';
import { Table } from './Table';
import { Lists } from './Lists';

export const GlobalStyles = () => (
	<>
		<Fonts />
		<BaseGlobalStyles />
		<Table />
		<Lists />
	</>
);
