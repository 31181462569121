import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { IChangeCallback } from './types/IChangeCallback';
import { IUpdateUserAttributesData } from '../../auth/user/domain/models/IUpdateUserAttributesData';
import { ICognitoUserInfo } from './types/ICognitoUserInfo';
import { IForgetPasswordData } from '../../auth/forget-password/domain/models/IForgetPasswordData';
import { ISignInData } from '../../auth/sign-in/domain/models/ISignInData';
import { ISignUpUserData } from '../../auth/user/domain/models/ISignUpUserData';
import { ICognitoUser } from './types/ICognitoUser';

interface ICognitoClientConfig {
	identityPoolId?: string;
	region?: string;
	userPoolId?: string;
	userPoolWebClientId?: string;
	mandatorySignIn: boolean;
}

export class CognitoClient {
	constructor(config: ICognitoClientConfig) {
		Auth.configure(config);
	}

	signIn({ email, password }: ISignInData) {
		return Auth.signIn(email, password);
	}

	signOut() {
		return Auth.signOut();
	}

	signUp(data: ISignUpUserData) {
		return Auth.signUp(data);
	}

	sendRecoveryLink({ email }: IForgetPasswordData) {
		return Auth.forgotPassword(email);
	}

	resetPassword(username: string, code: string, newPassword: string) {
		return Auth.forgotPasswordSubmit(username, code, newPassword);
	}

	getCurrentUser(): Promise<CognitoUser> {
		return Auth.currentAuthenticatedUser();
	}

	getCurrentUserInfo(): Promise<ICognitoUserInfo | undefined> {
		return Auth.currentUserInfo();
	}

	getCurrentUserAttributes(user: CognitoUser) {
		return Auth.userAttributes(user);
	}

	async verifyUserAttribute() {
		const user = await this.getCurrentUser();
		return Auth.verifyUserAttribute(user, 'phone_number');
	}

	async setUserMfaSMS(code: string) {
		const user = await this.getCurrentUser();

		const { phone_number: phoneNumber } = (user as ICognitoUser).attributes;
		if (!phoneNumber) {
			throw new Error('Please add phone number');
		}

		await Auth.verifyUserAttributeSubmit(user, 'phone_number', code);

		return Auth.setPreferredMFA(user, 'SMS');
	}

	async removeUserMfa() {
		const user = await this.getCurrentUser();
		return Auth.setPreferredMFA(user, 'NOMFA');
	}

	async confirmSmsSignIn(userSignInDetails: ICognitoUser | any, code: string) {
		return Auth.confirmSignIn(userSignInDetails, code);
	}

	async updateUserAttributes({ address, ...rest }: IUpdateUserAttributesData) {
		const user = await this.getCurrentUser();

		await Auth.updateUserAttributes(user, {
			address: JSON.stringify(address),
			...rest,
		});
	}

	async changePassword(oldPassword: string, newPassword: string) {
		const user = await this.getCurrentUser();

		return Auth.changePassword(user, oldPassword, newPassword);
	}

	addChangeListener(callback: IChangeCallback) {
		Hub.listen('auth', callback);
	}

	removeChangeListener(callback: IChangeCallback) {
		Hub.remove('auth', callback);
	}
}
