import { AxiosRequestConfig } from 'axios';
import { AbstractService } from './AbstractService';
import { ISkydiveWeatherResponse } from '../types/ISkydiveWeatherResponse';

export interface IWeatherParams {
	forecast?: '1' | '0';
	iata: string;
	language?: string;
}

export class WeatherService extends AbstractService {
	/**
	 * Path of the resource to be used by the service.
	 */
	public static readonly URI_PATH = '/weather/get';

	public static readonly DEFAULT_LANGUAGE = 'en-US';

	public get(
		{ iata, forecast, language }: IWeatherParams,
		requestConfig: AxiosRequestConfig = {},
	): Promise<ISkydiveWeatherResponse> {
		const uri = WeatherService.URI_PATH;

		const params = new URLSearchParams();

		params.append('airport', iata as string);
		params.append('forecast', forecast ?? '0');
		params.append('language', language ?? WeatherService.DEFAULT_LANGUAGE);

		return this.client.request(uri, { ...requestConfig, data: params });
	}
}
