import { ICustomConfig } from '@m2/blueprint-ui';
import { theme } from '../styles/theme';
import { dateTimeFormat } from '../i18n/dateTimeFormat';
import { RouterLink } from '../components/common/link/RouterLink';
import { useNavigate } from '../navigation';

export function getUiConfig(): ICustomConfig {
	return {
		theme,
		dateTimeFormat,
		RouterLinkComponent: RouterLink,
		useNavigate,
	};
}
