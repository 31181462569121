import React from 'react';
import Head from 'next/head';
import { CenterWrapper } from '../../../pages/404';
import IconError from '../../../assets/error-icon.svg?reference';

export const PreviewModeDisabled = () => (
	<>
		<Head>
			<meta name="robots" content="noindex" />
		</Head>
		<CenterWrapper>
			<img src={IconError} alt="" />
			<p>
				Preview Mode is disabled. Please use the Preview button in Contentful to
				enable Preview Mode.
			</p>
		</CenterWrapper>
	</>
);
