import { createRemoteStore } from '@m2/blueprint-utils';
import { IPoi } from '../types/IPoi';
import { IGetAllPois } from '../use-cases/getAllPois';

export type PoiStore = ReturnType<typeof createPoiStore>;

export const createPoiStore = (getAllPois: IGetAllPois) =>
	createRemoteStore<void, IPoi[]>({
		loader: getAllPois,
	});
