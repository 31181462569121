import { makeAutoObservable } from 'mobx';
import { getFilteredPoiItems } from '../use-cases/getFilteredPoiItems';
import { Categories } from '../types/Categories';
import { AllTagsArray, AllTagsLabel } from '../types/Tags';
import { TerminalList, Terminals } from '../types/Terminals';

import { getOrderedPoiItems } from '../use-cases/getOrderedPoiItems';
import { PoiStore } from './createPoiStore';
import { getClusteredPoisItems } from '../use-cases/getClusteredPoisItems';
import { isPoiCluster } from '../use-cases/isPoiCluster';

export type PoiSearchStore = ReturnType<typeof createPoiSearchStore>;

const DEFAULT_INDEX = 6;

export const createPoiSearchStore = (
	poiStore: PoiStore,
	initialTag?: string,
	initialTerminal?: string,
	showAllResults = false,
) => {
	const store = makeAutoObservable({
		category: Categories.All as string,
		tag: initialTag ?? AllTagsLabel,
		terminal: initialTerminal ?? (Terminals.AllTerminals as string),
		userQuery: '',
		showUntilIndex: DEFAULT_INDEX,
		isPoiOpenStatusEnabled: false,
		nowOpen: false,
		mobileOrdering: false,
		activeClusterName: undefined as string | undefined,
		setIsPoiOpenStatusEnabled(isEnabled: boolean) {
			this.isPoiOpenStatusEnabled = isEnabled;
		},
		setNowOpen(isOpen: boolean) {
			this.nowOpen = isOpen;
		},
		setMobileOrdering(isOrdering: boolean) {
			this.mobileOrdering = isOrdering;
		},
		clearSearchInput() {
			this.setUserQuery('');
		},
		setShowUntilIndex(newIndex: number) {
			this.showUntilIndex = newIndex;
		},
		resetIndex() {
			this.showUntilIndex = DEFAULT_INDEX;
		},
		setCategory(category: Categories) {
			this.resetIndex();
			this.category = category;

			if (category === Categories.Shops) {
				this.mobileOrdering = false;
			}
			if (category === Categories.Services) {
				this.mobileOrdering = false;
			}
		},
		setTag(tag: string) {
			this.resetIndex();
			this.tag = tag;
		},
		setTerminal(terminal: string) {
			this.resetIndex();
			this.terminal = terminal;
		},
		setUserQuery(userQuery: string) {
			this.resetIndex();
			this.userQuery = userQuery;
		},
		setActiveCluster(cluster: string | undefined) {
			this.activeClusterName = cluster;
		},
		get filteredPois() {
			if (!poiStore.value) {
				return [];
			}

			return getFilteredPoiItems(poiStore.value, {
				category: this.category,
				tag: this.tag,
				terminal: this.terminal,
				userQuery: this.userQuery,
				nowOpen: this.nowOpen,
				mobileOrdering: this.mobileOrdering,
			});
		},
		get clusteredPois() {
			return getClusteredPoisItems(this.filteredPois);
		},
	});

	return {
		get pois() {
			let pois = getOrderedPoiItems(store.clusteredPois);
			if (!showAllResults) {
				pois = pois.slice(0, store.showUntilIndex);
			}
			return pois;
		},
		get tags() {
			return Object.values(AllTagsArray).filter((tag) =>
				tag.toLowerCase().includes(store.userQuery.toLowerCase()),
			);
		},
		get terminals() {
			return TerminalList.filter((terminal) =>
				terminal.toLowerCase().includes(store.userQuery.toLowerCase()),
			);
		},
		setCategory: store.setCategory.bind(store),
		setTag: store.setTag.bind(store),
		setTerminal: store.setTerminal.bind(store),
		setUserQuery: store.setUserQuery.bind(store),
		setNowOpen: store.setNowOpen.bind(store),
		setMobileOrdering: store.setMobileOrdering.bind(store),
		clearSearchInput: store.clearSearchInput.bind(store),
		get isPoiOpenStatusEnabled() {
			return store.isPoiOpenStatusEnabled;
		},
		setIsPoiOpenStatusEnabled: store.setIsPoiOpenStatusEnabled.bind(store),
		get category() {
			return store.category;
		},
		get mobileOrdering() {
			return store.mobileOrdering;
		},
		get nowOpen() {
			return store.nowOpen;
		},
		get tag() {
			return store.tag;
		},
		get terminal() {
			return store.terminal;
		},
		get userQuery() {
			return store.userQuery;
		},
		get hasShowAll() {
			return !showAllResults && poiStore.value
				? store.showUntilIndex < store.clusteredPois.length
				: false;
		},
		get activeClusterName() {
			return store.activeClusterName;
		},
		get activeCluster() {
			if (!store.activeClusterName) {
				return undefined;
			}
			const item = store.clusteredPois.find(
				({ name }) => name === store.activeClusterName,
			);
			return item && isPoiCluster(item) ? item : undefined;
		},
		setActiveCluster: store.setActiveCluster.bind(store),
		showAll() {
			const totalItems = poiStore.value?.length ?? 0;
			store.setShowUntilIndex(totalItems);
		},
	};
};
