import React from 'react';
import { CookieConsentStore } from './CookieConsentStore';

const cookieConsentStore = new CookieConsentStore();

export const CookieConsentContext = React.createContext({
	cookieConsentStore,
});

export const useCookieConsentStore = () =>
	React.useContext(CookieConsentContext);
