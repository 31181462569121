import React, { Fragment, useState } from 'react';
import styled from '@emotion/styled';
import { ITheme, Responsive, StyledLinkText, themed } from '@m2/blueprint-ui';
import { IStyledProps } from '../../../Header';
import { getMenuItemMapping } from '../shared/getMenuItemMapping';
import { IMenuItemNavBar } from '../types/IMenuItemNavBar';
import { NAVIGATION_MENU_MARGIN_XL } from '../../constants';
import { DropDownMenuRow } from './DropDownMenuRow';
import { DropDownMenuListItemMobile } from './DropDownMenuListItemMobile';
import { IDropDownMenuProps } from '../types/IDropDownMenuProps';

export interface IDropDownMenuMobileProps extends IDropDownMenuProps {
	onClickLink: () => void;
}

export const DropDownMenuMobile = ({
	isTransparent,
	pathToPageIdMap,
	menuItems,
	onClickLink,
}: IDropDownMenuMobileProps) => {
	const [visibleIndex, setVisibleIndex] = useState<number | undefined>(
		undefined,
	);

	const menuItemNav = getMenuItemMapping(pathToPageIdMap, menuItems);

	if (!menuItemNav) {
		return null;
	}

	const handleClickBack = () => {
		setVisibleIndex(undefined);
	};

	return (
		<StyledMenuWrapper>
			{menuItemNav.map((menuItem: IMenuItemNavBar, index: number) => (
				// eslint-disable-next-line react/no-array-index-key
				<Fragment key={menuItem.key}>
					<StyledMenuItemLink
						isImageHeader={isTransparent}
						onClick={() => setVisibleIndex(index)}
					>
						<DropDownMenuRow menuItem={menuItem} onClickLink={onClickLink} />
					</StyledMenuItemLink>
					{index === visibleIndex && (
						<DropDownMenuListItemMobile
							menuItem={menuItem}
							isTransparent={isTransparent ?? false}
							layer={index}
							onClickLink={onClickLink}
							onClickBack={handleClickBack}
						/>
					)}
				</Fragment>
			))}
		</StyledMenuWrapper>
	);
};

const StyledMenuWrapper = styled.div`
	display: flex;
	> * {
		margin-right: ${NAVIGATION_MENU_MARGIN_XL};
	}
	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		flex-direction: column;
		> * {
			margin-right: 0;
		}
	}
`;

export const StyledMenuItemLink = styled.div<IStyledProps, ITheme>`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;

	${StyledLinkText} {
		color: ${themed(({ color }, { isScrolledDown, isImageHeader }) =>
			isScrolledDown
				? color.text.default
				: isImageHeader
				? color.text.inverted
				: color.text.default,
		)};
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		justify-content: space-between;
		height: 30px;
		margin-top: 10px;

		${StyledLinkText} {
			color: ${themed(({ color }) => color.text.default)};
		}
	}
`;
