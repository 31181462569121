import { AxiosRequestConfig } from 'axios';
import { AbstractService } from './AbstractService';
import { ISkydiveFlightsResponse } from '../types';

export class FlightListService extends AbstractService {
	/**
	 * Path of the resource to be used by the service.
	 */
	public static readonly URI_PATH = '/flights/get';

	public get(
		requestConfig: AxiosRequestConfig = {},
	): Promise<ISkydiveFlightsResponse> {
		const uri = FlightListService.URI_PATH;

		return this.client.request(uri, requestConfig);
	}
}
