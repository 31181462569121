import { ISiteSectionEntry } from '@m2/blueprint-contentful';
import { useRouter } from 'next/router';
import { getCurrentSiteSection } from '.';
import { useCommonData } from '../common-data/useCommonData';

export function useCurrentSiteSection(): ISiteSectionEntry | undefined {
	const { siteSections } = useCommonData();
	const router = useRouter();
	return getCurrentSiteSection(siteSections, router);
}
