import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { RouterEvent } from 'next/dist/client/router';

export const useTriggerOnRouteChange = (
	callback: () => void,
	routerEvent: RouterEvent = 'routeChangeStart',
) => {
	const router = useRouter();
	useEffect(() => {
		const handleRouteChange = () => {
			callback();
		};

		router.events.on(routerEvent, handleRouteChange);

		return () => {
			router.events.off(routerEvent, handleRouteChange);
		};
	}, []);
};
