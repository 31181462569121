import { IPathToPageIdMap } from '@m2/blueprint-contentful-routes';
import {
	IFooterEntry,
	IFooterLink,
} from '../../../contentful/types/IFooterEntry';
import { getUrlForLink } from '../../../navigation';
import { IFooterLinkSections } from './Footer';
import { ISocialLinkItemPlatform } from './FooterSocialLinks';

export function getFooterLinks(
	footer: IFooterEntry,
	pathToPageIdMap: IPathToPageIdMap,
) {
	function createLinkObject(link: IFooterLink) {
		return {
			title: link.fields.title,
			type: link.contentType,
			url: getUrlForLink(link, pathToPageIdMap) as string,
			key: link.id,
		};
	}

	const finePrintLinks = {
		links: footer.fields.finePrintSectionLinks?.map((link: IFooterLink) =>
			createLinkObject(link),
		),
	};

	const socialLinks = {
		title: footer.fields.socialLinksSectionTitle,
		links: [
			{
				url: footer.fields.socialLinksSectionFacebook,
				platform: ISocialLinkItemPlatform.Facebook,
			},
			{
				url: footer.fields.socialLinksSectionInstagram,
				platform: ISocialLinkItemPlatform.Instagram,
			},
			{
				url: footer.fields.socialLinksSectionTwitter,
				platform: ISocialLinkItemPlatform.Twitter,
			},
			{
				url: footer.fields.socialLinksSectionYouTube,
				platform: ISocialLinkItemPlatform.YouTube,
			},
			{
				url: footer.fields.socialLinksSectionPinterest,
				platform: ISocialLinkItemPlatform.Pinterest,
			},
			{
				url: footer.fields.socialLinksSectionLinkedIn,
				platform: ISocialLinkItemPlatform.LinkedIn,
			},
		],
	};

	const appLinks = {
		title: footer.fields.appLinksSectionTitle,
		appStore: footer.fields.appLinksSectionAppStore?.fields.url,
		playStore: footer.fields.appLinksSectionPlayStore?.fields.url,
	};

	const linkSections: IFooterLinkSections = [
		{
			key: Math.random().toString(),
			title: footer.fields.linkSection1_title,
			links: footer.fields.linkSection1_links.map((link: IFooterLink) =>
				createLinkObject(link),
			),
		},
		{
			key: Math.random().toString(),
			title: footer.fields.linkSection2_title,
			links: footer.fields.linkSection2_links.map((link: IFooterLink) =>
				createLinkObject(link),
			),
		},
		{
			key: Math.random().toString(),
			title: footer.fields.linkSection3_title,
			links: footer.fields.linkSection3_links.map((link: IFooterLink) =>
				createLinkObject(link),
			),
		},
	];

	return {
		finePrintLinks,
		linkSections,
		socialLinks,
		appLinks,
	};
}
