import { IEnrichedFlight } from '../../../shared/domain/types/IEnrichedFlight';
import { SearchType } from '../types/SearchType';

export function flightsTypeFilter(
	searchType: SearchType,
	flight: IEnrichedFlight,
) {
	if (searchType === SearchType.All) return true;
	return (
		(flight.arrival && searchType === SearchType.Arrivals) ||
		(!flight.arrival && searchType === SearchType.Departures)
	);
}
