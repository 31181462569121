import { ApiClient } from '../ApiClient';

export abstract class AbstractService {
	/**
	 * Instance of the Client class.
	 */
	protected client: ApiClient;

	/**
	 * constructor
	 * @param client
	 */
	constructor(client: ApiClient) {
		this.client = client;
	}
}
