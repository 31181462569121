import React from 'react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/browser';
import {
	ContentWrap,
	ArrowBackIcon,
	StyledIconLink,
	Responsive,
	contentWrapBreakpoint,
} from '@m2/blueprint-ui';
import illustration from '../../../assets/something-went-wrong.svg?reference';
import { isProductionBuild } from '../../../config/utils';

export const StyledErrorMessage = styled(ContentWrap)`
	margin-top: 40px;
	text-align: center;

	h2 {
		margin: 0 auto;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
		max-width: 500px;
		margin: 0 auto;
		padding: 40px 0;
	}

	a {
		display: inline-block;
		cursor: pointer;

		svg {
			margin-left: 0;
		}
	}

	@media (orientation: portrait) {
		img {
			max-height: 60vh;
		}
	}

	@media (${contentWrapBreakpoint}) {
		margin: 40px 0;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		img {
			padding: 60px 20px;
		}
	}
`;

export const ErrorMessage = () => (
	<StyledErrorMessage>
		<StyledIconLink href="/">
			<ArrowBackIcon variation="highlight" />
			{` Go Back home`}
		</StyledIconLink>
		<img src={illustration} alt="Something went wrong..." />
		{isProductionBuild() && (
			<>
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */}
				<a onClick={Sentry.showReportDialog}>Report feedback</a>
			</>
		)}
	</StyledErrorMessage>
);
