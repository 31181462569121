import { makeAutoObservable } from 'mobx';
import { IAirline } from '../../../../airlines/types';
import { IAirport } from '../../../../airports/types';
import { FlightListStore } from '../../../list/domain/stores/FlightListStore';
import { SearchFilters } from '../../../search/domain/types/SearchFilters';
import { generateAutoSuggestions } from '../../../search/domain/use-cases/generateAutoSuggestions';

import { FlightStore } from './FlightStore';

export type FlightSuggestionStore = ReturnType<
	typeof createFlightSuggestionStore
>;

export interface IFlightSuggestionStoreProps {
	flightStore: FlightStore;
	flightListStore: FlightListStore;
	airports: IAirport[];
	airlines: IAirline[];
	minLength?: number;
}

export function createFlightSuggestionStore({
	flightStore,
	flightListStore,
	airports,
	airlines,
	minLength = 2,
}: IFlightSuggestionStoreProps) {
	return makeAutoObservable({
		input: '',
		get finishedInitialLoad() {
			return flightListStore.finishedInitialLoad;
		},
		get isLoading() {
			return flightListStore.isLoading;
		},
		load() {
			return flightListStore.load();
		},
		get suggestionList() {
			if (this.input.length < minLength) return [];
			return generateAutoSuggestions({
				flights: flightStore.flights,
				airports,
				airlines,
				userInput: this.input,
			});
		},
		setSuggestionInput(input: string) {
			this.input = input;
		},
		applySearch(query?: SearchFilters) {
			flightListStore.setSearchFilter(query);
		},
		get searchFlightSearchType() {
			return flightListStore.searchType;
		},
	});
}
