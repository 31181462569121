import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ChevronDownIcon, ITheme, Responsive, themed } from '@m2/blueprint-ui';
import Link from 'next/link';
import { ISiteSectionEntry } from '@m2/blueprint-contentful';

import { IStyledProps } from '../Header';
import { NAVIGATION_MENU_MARGIN_M } from './constants';

import { useCurrentSiteSection } from '../../../../navigation';

interface IDropdownSiteSections {
	isScrolledDown: boolean;
	isImageHeader?: boolean;
	siteSections: ISiteSectionEntry[];
}

const DropdownSiteSections = ({
	isScrolledDown,
	isImageHeader,
	siteSections,
}: IDropdownSiteSections) => {
	const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
	const currentSiteSection = useCurrentSiteSection();

	const mainSiteSection = siteSections.find(
		(siteSection) => !siteSection.fields.slug,
	);

	const otherSiteSections = siteSections.filter(
		(siteSection) => siteSection !== mainSiteSection,
	);

	return (
		<>
			<StyledDropdownWrapper
				onClick={() => setIsDropdownVisible(!isDropdownVisible)}
				onMouseEnter={() => setIsDropdownVisible(true)}
				isScrolledDown={isScrolledDown}
				isImageHeader={isImageHeader}
			>
				<span>
					{currentSiteSection ? currentSiteSection.fields.title : 'Passengers'}
				</span>
				<ChevronDownIcon
					variation={
						(isScrolledDown && `brand`) ||
						(isImageHeader ? 'inverted' : 'brand')
					}
				/>
			</StyledDropdownWrapper>

			<StyledMouseArea
				onMouseEnter={() => setIsDropdownVisible(true)}
				onMouseLeave={() => setIsDropdownVisible(false)}
			/>
			{isDropdownVisible && (
				<StyledDropdownMenu
					onMouseEnter={() => setIsDropdownVisible(true)}
					onMouseLeave={() => setIsDropdownVisible(false)}
					isDropdownVisible={isDropdownVisible}
				>
					<StyledTriangle />
					{mainSiteSection && (
						<Link href={`/${mainSiteSection.fields.slug ?? ''}`}>
							<StyledDropdownItem>
								{mainSiteSection.fields.title}
							</StyledDropdownItem>
						</Link>
					)}
					{otherSiteSections.map((siteSection: ISiteSectionEntry) => (
						<Link
							href={`/${siteSection.fields.slug ?? ''}`}
							key={siteSection.id}
						>
							<StyledDropdownItem>
								{siteSection.fields.title}
							</StyledDropdownItem>
						</Link>
					))}
				</StyledDropdownMenu>
			)}
		</>
	);
};

export default DropdownSiteSections;

export const StyledDropdownMenu = styled.div<
	{ isDropdownVisible: boolean },
	ITheme
>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 70px;
	right: 167px;
	width: 263px;
	border-radius: 4px;
	background-color: white;
	padding-left: 16px;
	padding-right: 16px;
	padding-bottom: 8px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	transition: opacity 0.15s ease-in-out;
	opacity: ${({ isDropdownVisible }) => (isDropdownVisible ? 1 : 0)};

	> * {
		margin-bottom: 14px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		display: none;
	}

	@media (max-width: 1300px) {
		right: 102px;
	}

	@media (max-width: 1100px) {
		right: 20px;
	}
`;

export const StyledDropdownWrapper = styled.button<IStyledProps, ITheme>`
	background-color: transparent;
	border: 0;
	font-family: inherit;
	font-size: 16px;
	color: ${themed(({ color }, { isScrolledDown, isImageHeader }) =>
		isScrolledDown
			? color.text.default
			: isImageHeader
			? color.text.inverted
			: color.text.default,
	)};
	margin-right: ${NAVIGATION_MENU_MARGIN_M};
	cursor: pointer;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		display: none;
	}
`;

export const StyledTriangle = styled.div<{}, ITheme>`
	position: relative;
	top: -11px;
	left: 99px;
	width: 0;
	height: 0;
	border-left: 16px solid transparent;
	border-right: 16px solid transparent;
	border-bottom: 16px solid white;
`;

export const StyledDropdownItem = styled.span<{}, ITheme>`
	font-size: 16px;
	color: ${themed(({ color }) => color.text.default)};

	&:hover {
		color: ${themed(({ color }) => color.brand)};
		cursor: pointer;
	}
`;

export const StyledMouseArea = styled.div`
	position: absolute;
	top: 20px;
	transform: translateX(-33px);
	width: 263px;
	height: 50px;
	z-index: 10;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		display: none;
	}
`;
