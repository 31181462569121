import { ApiClient } from './ApiClient';
import {
	CheckpointRecommendationService,
	FlightListService,
	ShuttleStopsService,
} from './services';
import { ParkingAvailabilityService } from './services/ParkingAvailabilityService';
import { ParkingRatesService } from './services/ParkingRatesService';
import { WeatherService } from './services/WeatherService';
import { ShuttleVehiclesService } from './services/ShuttleVehiclesService';
import { ShuttleStopEtasService } from './services/ShuttleStopEtasService';
import { ShuttleRoutesService } from './services/ShuttleRoutesService';
import { BusStopsSchedulesService } from './services/BusStopsSchedules';
import { IShuttleServiceType } from '../live-shuttle-tracking/tracker/domain/types/IShuttleServiceType';
import { getBaseUriPath } from './utils/getBaseUriPath';
import { WaitTimesService } from './services/WaitTimesService';

export class Skydive {
	static API_VERSION = '160';

	static API_KEY = '87856E0636AA4BF282150FCBE1AD63DE';

	protected client: ApiClient;

	constructor(baseUrl: string) {
		this.client = new ApiClient({
			baseURL: baseUrl,
			method: 'POST',
			headers: {
				'Api-Version': Skydive.API_VERSION,
				'Api-Key': Skydive.API_KEY,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		});
	}

	public flights() {
		return new FlightListService(this.client);
	}

	public weather() {
		return new WeatherService(this.client);
	}

	public checkpoints() {
		return new WaitTimesService(this.client);
	}

	public checkpointRecommendation() {
		return new CheckpointRecommendationService(this.client);
	}

	public shuttleStops(serviceType: IShuttleServiceType) {
		const uriPath = getBaseUriPath(serviceType);

		return new ShuttleStopsService(this.client, uriPath);
	}

	public shuttleVehicles(serviceType: IShuttleServiceType) {
		const uriPath = getBaseUriPath(serviceType);

		return new ShuttleVehiclesService(this.client, uriPath);
	}

	public shuttleStopEtas(serviceType: IShuttleServiceType) {
		const uriPath = getBaseUriPath(serviceType);

		return new ShuttleStopEtasService(this.client, uriPath);
	}

	public shuttleRoutes(serviceType: IShuttleServiceType) {
		const uriPath = getBaseUriPath(serviceType);

		return new ShuttleRoutesService(this.client, uriPath);
	}

	public busStopsSchedules(serviceType: IShuttleServiceType) {
		const uriPath = getBaseUriPath(serviceType);

		return new BusStopsSchedulesService(this.client, uriPath);
	}

	public parkingAvailability() {
		return new ParkingAvailabilityService(this.client);
	}

	public parkingRates() {
		return new ParkingRatesService(this.client);
	}
}
