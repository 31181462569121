import { PoiOpenStatus } from '@m2/blueprint-locuslabs-sdk';
import { IPoi } from '../types/IPoi';
import { IPoiCluster } from '../types/IPoiCluster';
import { isPoiCluster } from './isPoiCluster';

/**
 * We need to split into two arrays as open POIs need to show first
 * but should also be alphabetically sorted
 */
export function getOrderedPoiItems(pois: Array<IPoi | IPoiCluster>) {
	const openPois = pois.filter((poi) => poi.openStatus === PoiOpenStatus.Open);
	const unknownPois = pois.filter(
		(poi) => poi.openStatus === PoiOpenStatus.Unknown,
	);
	const closedPois = pois.filter(
		(poi) => poi.openStatus === PoiOpenStatus.Closed,
	);

	// Sort each array alphabetically and then combine each array
	return [
		...sortAlphabetically(openPois),
		...sortAlphabetically(unknownPois),
		...sortAlphabetically(closedPois),
	];
}

// Sorting function that includes numeric ordering for clustered POIs.
function sortAlphabetically(pois: Array<IPoi | IPoiCluster>) {
	return pois.sort((a, b) => {
		if (a.name === b.name && !isPoiCluster(a) && !isPoiCluster(b)) {
			const locationA = a.nearbyLandmark || a.terminal;
			const locationB = b.nearbyLandmark || b.terminal;

			if (locationA && locationB) {
				return locationA.localeCompare(locationB, undefined, {
					numeric: true,
				});
			}
		}

		return a.name.localeCompare(b.name);
	});
}
