import { FlightStore } from '../../../shared/domain/stores/FlightStore';
import { FlightListStore } from './FlightListStore';
import { FlightEnrichStore } from '../../../shared/domain/stores/FlightEnrichStore';

export function createFlightListStore(
	flightStore: FlightStore,
	flightEnrichStore: FlightEnrichStore,
) {
	return new FlightListStore(flightStore, flightEnrichStore);
}
