import { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { createRemoteConfigStore } from './createRemoteConfigStore';
import { getBuildConfig } from './getBuildConfig';
import { IRemoteConfig } from './types/IRemoteConfig';

const remoteConfig = createRemoteConfigStore(getBuildConfig());

export const useRemoteConfig = <Key extends keyof IRemoteConfig>(key: Key) => {
	useEffect(() => {
		remoteConfig.load();
	}, []);

	return useObserver(() => {
		const value = remoteConfig.config[key];
		const { error } = remoteConfig;

		return { value, error };
	});
};
