import styled from '@emotion/styled';
import { ITheme, StyledLinkText, themed } from '@m2/blueprint-ui';
import React, { useState } from 'react';
import { IStyledProps } from '../../../Header';
import { IMenuItemNavBar } from '../types/IMenuItemNavBar';
import { DropDownMenuListItemMobile } from './DropDownMenuListItemMobile';
import { DropDownMenuRow } from './DropDownMenuRow';

interface IDropDownItemMobileProps {
	subMenus: IMenuItemNavBar[];
	isTransparent: boolean;
	onClickLink: () => void;
}

export const DropDownMenuItemMobile = ({
	subMenus,
	isTransparent,
	onClickLink,
}: IDropDownItemMobileProps) => {
	const [visibleIndex, setVisibleIndex] = useState<number | undefined>(
		undefined,
	);

	const handleClickBack = () => {
		setVisibleIndex(undefined);
	};

	if (subMenus.length === 0) {
		return null;
	}

	return (
		<>
			{subMenus.map((subMenu, index) => (
				<StyledMenuContainer
					key={subMenu.key}
					onClick={() => setVisibleIndex(index)}
				>
					<DropDownMenuRow menuItem={subMenu} onClickLink={onClickLink} />
					{index === visibleIndex && (
						<DropDownMenuListItemMobile
							menuItem={subMenu}
							isTransparent={isTransparent ?? false}
							layer={index}
							onClickLink={onClickLink}
							onClickBack={handleClickBack}
						/>
					)}
				</StyledMenuContainer>
			))}
		</>
	);
};

const StyledMenuContainer = styled.div<IStyledProps, ITheme>`
	height: 50px;
	display: flex;
	align-items: center;
	${StyledLinkText} {
		color: ${themed(({ color }) => color.text.default)};
	}
`;
