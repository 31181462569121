import { IPoi } from '../types/IPoi';
import { AllTagsLabel } from '../types/Tags';

export function itemsTagFilter(tag: string | null, poi: IPoi) {
	if (!tag || tag === AllTagsLabel) return true;

	return (
		poi.displayTags?.includes(tag.toLowerCase()) ||
		poi.tags?.includes(tag.toLowerCase())
	);
}
