import styled from '@emotion/styled';
import React from 'react';
import { Color } from '../../../../styles/Color';

interface IProps {
	yTotal: number;
	yPosition: number;
}

export const OrangeLine = ({ yTotal, yPosition }: IProps) => {
	const width = `${(yPosition / yTotal) * 100}vw`;
	return <Line style={{ width }} />;
};

const Line = styled.div`
	height: 3px;
	background-color: ${Color.PrimaryOrange100};
	transition: all 0.05s ease-in-out;
`;
