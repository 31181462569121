import { AxiosRequestConfig } from 'axios';
import { ApiClient } from '@m2/blueprint-utils';
import { AbstractService } from './AbstractService';
import { ISkydiveBusStopsSchedulesResponse } from '../types/ISkydiveBusStopsSchedulesResponse';

export class BusStopsSchedulesService extends AbstractService {
	/**
	 * Path of the resource to be used by the service.
	 */
	private readonly uriPath: string;

	public static readonly URI_PATH = '/schedules';

	constructor(client: ApiClient, uriPath: string) {
		super(client);
		this.uriPath = uriPath;
	}

	public getBusStopsSchedules(
		requestConfig: AxiosRequestConfig = {
			method: 'GET',
		},
	): Promise<ISkydiveBusStopsSchedulesResponse> {
		const uri = this.uriPath + BusStopsSchedulesService.URI_PATH;

		return this.client.request(uri, requestConfig);
	}
}
