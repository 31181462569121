import { useEffect, useState } from 'react';
import { SearchOverlayViewModel } from '../ui/SearchOverlayViewModel';

const useDoesContentFitWindow = (
	viewModel: SearchOverlayViewModel,
	searchItemsRef: React.RefObject<HTMLDivElement>,
	bottomPadding: number,
) => {
	const [itemsFitWindow, setItemsFitWindow] = useState<boolean>(true);

	useEffect(() => {
		const checkContentHeight = () => {
			if (searchItemsRef.current) {
				const itemsAvailableHeight =
					searchItemsRef.current.offsetHeight + bottomPadding;

				// If there is a scrollbar, the items don't fit the page => hide 'End of Results' button
				setItemsFitWindow(
					searchItemsRef.current.scrollHeight <= itemsAvailableHeight,
				);
			}
		};

		// Check on mount
		checkContentHeight();

		// Re-check on window resize (in case of responsive design)
		window.addEventListener('resize', checkContentHeight);

		// Cleanup the event listener on unmount
		return () => {
			window.removeEventListener('resize', checkContentHeight);
		};
	}, [viewModel.recentSearchViewModels, viewModel.resultItemViewModels]);

	return itemsFitWindow;
};

export default useDoesContentFitWindow;
