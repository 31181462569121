import React from 'react';
import Head from 'next/head';
import { isPublicRelease } from '../../../config/utils/isPublicRelease';

interface IPageMetaDataProps {
	title?: string;
	description?: string;
}

export const PageMetaData = ({ title, description }: IPageMetaDataProps) => (
	<Head>
		{title && <title>{title}</title>}
		{title && <meta name="og:title" property="og:title" content={title} />}
		{description && (
			<meta
				name="og:description"
				property="og:description"
				content={description}
			/>
		)}
		{description && <meta name="description" content={description} />}
		{/* We use the meta tag to block indexing of non-public releases */}
		{/* See: https://support.google.com/webmasters/answer/6062608?hl=en */}
		{!isPublicRelease() && <meta name="robots" content="noindex, nofollow" />}
		<meta name="apple-itunes-app" content="app-id=587942745" />
	</Head>
);
