import React from 'react';

import styled from '@emotion/styled';
import { ShimmerPlaceholder, ShimmerPlaceholderText } from '@m2/blueprint-ui';
import { Color } from '../../styles/Color';

export const UnifiedSearchItemShimmer = () => (
	<StyledItemContainer>
		<StyledShimmerPlaceholderImage />
		<StyledContentContainer>
			<StyledShimmerPlaceholderTitle />
			<StyledShimmerPlaceholderText />
		</StyledContentContainer>
	</StyledItemContainer>
);

const StyledItemContainer = styled.div`
	display: flex;
	align-items: center;
	height: 70px;
	padding: 15px 12px;
	background-color: ${Color.PrimaryWhite};
	border-radius: 8px;
`;

const StyledContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 15px;
	gap: 12px;
`;

const StyledShimmerPlaceholderImage = styled(ShimmerPlaceholder)`
	height: 20px;
	width: 20px;
	background-color: ${Color.Neutral150};
	border-radius: 4px;
`;

const StyledShimmerPlaceholderTitle = styled(ShimmerPlaceholderText)`
	width: 160px;
	height: 16px;
	background-color: ${Color.Neutral150};
	border-radius: 4px;
`;

const StyledShimmerPlaceholderText = styled(ShimmerPlaceholderText)`
	width: 62px;
	height: 12px;
	background-color: ${Color.Neutral150};
	border-radius: 4px;
`;
