export function removeEmptyValues<T>(object: Partial<T>): Partial<T> {
	return Object.entries(object).reduce((previous, [key, value]) => {
		if (!value) {
			return previous;
		}

		return {
			...previous,
			[key]: value,
		};
	}, {} as Partial<T>);
}
