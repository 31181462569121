import { useRouter } from 'next/router';
import { UrlObject } from 'url';

export type Url = UrlObject | string;

export function useNavigate() {
	const router = useRouter();

	return (url: Url) => {
		router.push(url).then(() => window.scrollTo(0, 0));
	};
}
