import { RemoteDataStore } from '@m2/blueprint-utils';
import { action, makeObservable, observable } from 'mobx';
import { ISkydiveWaitTimesResponse } from '../../../skydive/types/ISkydiveWaitTimesResponse';
import { Skydive } from '../../../skydive';
import { IWaitTimesParams } from '../../../skydive/services/WaitTimesService';
import { mapWaitTimes } from '../mappers/mapWaitTimes';
import { Crowdedness } from '../../../checkpoints/types/Crowdedness';

export interface IWaitTime {
	open: boolean;
	isDisplayable: boolean;
	poiId: string | null;
	waitMinutes: number | string;
	crowdedness: Crowdedness;
}

export class WaitTimesStore extends RemoteDataStore<
	{},
	ISkydiveWaitTimesResponse
> {
	public waitTimes?: IWaitTime[];

	protected readonly api: Skydive;

	constructor(api: Skydive) {
		super();

		makeObservable<WaitTimesStore, 'handleResponse'>(this, {
			waitTimes: observable,
			handleResponse: action,
			clear: action,
		});

		this.api = api;
	}

	public load(params: IWaitTimesParams) {
		return super.load(params);
	}

	protected async performRequest(params: IWaitTimesParams) {
		return this.api.checkpoints().get(params);
	}

	protected handleResponse(waitTimes: ISkydiveWaitTimesResponse) {
		this.waitTimes = mapWaitTimes(waitTimes);
	}

	public clear() {
		this.waitTimes = undefined;
	}
}
