import React from 'react';
import styled from '@emotion/styled';
import { ContentWrap, Responsive } from '@m2/blueprint-ui';
import { Color } from '../../../styles/Color';

export const StyledNoResultsMessage = styled(ContentWrap)`
	text-align: center;
	padding: 130px 0px 40px 0px;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		padding: 80px 0px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding: 40px 0px;
	}

	h3 {
		margin: 0 auto;
		font-size: 24px;
		color: ${Color.PrimaryBlack};

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			font-size: 20px;
		}
	}

	p {
		margin: 0 auto;
		font-size: 15px;
		padding-top: 8px;
		color: #494949;

		@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
			max-width: 180px;
		}
	}

	img {
		display: block;
		width: 100%;
		height: auto;
		max-width: 150px;
		max-height: 60vh;
		margin: 0 auto;
		padding: 60px 0;

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			padding: 60px 0px 50px 0px;
		}
	}
`;

interface INoResultsMessageProps {
	title: string;
	body: string;
	imageSource: string;
	imageAlt: string;
}

export const NoResultsMessage = ({
	title,
	body,
	imageSource,
	imageAlt,
}: INoResultsMessageProps) => (
	<StyledNoResultsMessage>
		<img src={imageSource} alt={imageAlt} />
		<h3>{title}</h3>
		<p>{body}</p>
	</StyledNoResultsMessage>
);
