import React from 'react';
import styled from '@emotion/styled';
import {
	ChevronDownIcon,
	ITheme,
	LinkText,
	Responsive,
	StyledIconLink,
	StyledLinkText,
	themed,
} from '@m2/blueprint-ui';
import { getMenuItemMapping } from '../shared/getMenuItemMapping';
import { IMenuItemNavBar } from '../types/IMenuItemNavBar';
import { DropDownMenuItem } from './DropDownMenuItem';
import { IDropDownMenuProps } from '../types/IDropDownMenuProps';
import { useThrottledState } from '../../../../../../utils/useThrottledState';
import { MENU_TRANSITION_DELAY } from './constants';
import { IStyledProps } from '../../../Header';

export const DropDownMenu = ({
	isTransparent,
	isScrolledDown,
	pathToPageIdMap,
	menuItems,
}: IDropDownMenuProps) => {
	const [visibleIndex, setVisibleIndex] = useThrottledState<number | undefined>(
		MENU_TRANSITION_DELAY,
		undefined,
	);

	const menuItemNav = getMenuItemMapping(pathToPageIdMap, menuItems);

	if (!menuItemNav) {
		return null;
	}

	return (
		<StyledMenuWrapper>
			{menuItemNav.map((menuItem: IMenuItemNavBar, index: number) => (
				<StyledMenuItemLink
					isImageHeader={isTransparent}
					isScrolledDown={isScrolledDown}
					key={menuItem.id}
					onFocus={() => setVisibleIndex(index)}
					onBlur={() => setVisibleIndex(undefined)}
					onMouseEnter={() => setVisibleIndex(index)}
					onMouseLeave={() => setVisibleIndex(undefined)}
				>
					<StyledIconLink href={menuItem.url}>
						<LinkText>{menuItem.title}</LinkText>
					</StyledIconLink>

					{menuItem.subMenus?.length > 0 && (
						<StyledListWrapper>
							<ChevronDownIcon variation="brand" />
							<StyledSubMenu isVisible={index === visibleIndex}>
								<DropDownMenuItem
									subMenus={menuItem.subMenus}
									isTransparent={isTransparent ?? false}
									isScrolledDown={isScrolledDown ?? false}
								/>
							</StyledSubMenu>
						</StyledListWrapper>
					)}
				</StyledMenuItemLink>
			))}
		</StyledMenuWrapper>
	);
};

const StyledMenuWrapper = styled.div`
	display: flex;
	width: 100%;
	gap: 40px;
	@media (${Responsive.getMediaQueryForBreakpoint('tabletXL')}) {
		justify-content: space-between;
		gap: 0;
	}
	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		flex-direction: column;
		> * {
			margin-right: 0;
		}
	}
`;

export const StyledSubMenu = styled.div<{ isVisible: boolean }>`
	position: absolute;
	top: 30px;
	left: 0px;
	flex-direction: column;
	display: flex;
	width: ${({ isVisible }) => (isVisible ? '255px' : 0)};
	height: ${({ isVisible }) => (isVisible ? 'auto' : 0)};
	overflow: ${({ isVisible }) => (isVisible ? 'initial' : 'hidden')};
	border-radius: 4px;
	background-color: white;
	padding: 8px 0;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
	transition: opacity 0.35s ease-in-out, transform 0.35s ease-in-out;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
	transform: translateY(${({ isVisible }) => (isVisible ? '0' : '10px')});
`;

export const StyledMenuItemLink = styled.div<IStyledProps, ITheme>`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	${StyledLinkText} {
		color: ${themed(({ color }, { isScrolledDown, isImageHeader }) =>
			isScrolledDown
				? color.text.default
				: isImageHeader
				? color.text.inverted
				: color.text.default,
		)};
	}
`;

const StyledListWrapper = styled.div``;
