export const AuthConfig = {
	signedOutPages: ['sign-in', 'sign-up'],
	signedInPages: [
		'account',
		'account/personal-details',
		'account/vehicles',
		'account/parking-reservations',
		'account/security-preferences',
	],
};
