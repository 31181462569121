export const TerminalList = [
	'All Terminals',
	'Terminal A',
	'Terminal B',
	'Terminal C',
	'Terminal D',
	'Terminal E',
];

export enum Terminals {
	AllTerminals = 'All Terminals',
	TerminalA = 'Terminal A',
	TerminalB = 'Terminal B',
	TerminalC = 'Terminal C',
	TerminalD = 'Terminal D',
	TerminalE = 'Terminal E',
}
