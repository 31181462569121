import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { trackPage } from './utils/trackPage';
import { useIsTrackingEnabled } from './useIsTrackingEnabled';
import { enableTracking } from './utils/enableTracking';

export const usePageTracking = () => {
	const router = useRouter();
	const isTrackingEnabled = useIsTrackingEnabled();

	useEffect(() => {
		if (isTrackingEnabled) {
			enableTracking();
		}
	}, [isTrackingEnabled]);

	useEffect(() => {
		const handleRouteChange = (url: string) => {
			if (isTrackingEnabled) {
				trackPage(url);
			}
		};

		router.events.on('routeChangeComplete', handleRouteChange);

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [router.events, isTrackingEnabled]);
};
