import { ILocale as IBaseLocale } from '@m2/blueprint-contentful-routes';

export enum LocaleCode {
	EnUs = 'en-US',
}

export interface ILocale extends IBaseLocale {
	code: LocaleCode;
}

export const defaultLocale: ILocale = {
	code: LocaleCode.EnUs,
	prefix: undefined,
};
