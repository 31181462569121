import { getPublicConfig } from '../config';
import { Skydive } from './Skydive';

export * from './Skydive';
export * from './ApiClient';

let skydive: Skydive;

export function getSkydiveClient() {
	if (skydive) {
		return skydive;
	}

	const { skydiveBaseUri } = getPublicConfig();
	skydive = new Skydive(skydiveBaseUri);

	return skydive;
}
