import { isExternalUrl } from '@m2/blueprint-ui';
import { getBaseUrl } from '../utils/getBaseUrl';

export function getLinkPropsForUrl(url: string, key?: string) {
	const props = {
		href: url,
		key: key || Math.random().toString(),
	};

	const baseUrl = getBaseUrl();

	if (!baseUrl || !isExternalUrl(url, baseUrl)) {
		return props;
	}

	return {
		...props,
		target: '_blank',
		rel: 'noopener',
	};
}
