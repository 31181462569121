import React, {
	Dispatch,
	PropsWithChildren,
	useEffect,
	useReducer,
} from 'react';
import log from 'loglevel';

import { AuthContext } from './AuthContext';
import { useAuthRedirect } from '../hooks/useAuthRedirect';
import { cognitoClient } from '../../../common/cognito';
import { ICognitoUser } from '../../../common/cognito/types/ICognitoUser';

enum ActionTypes {
	SetUserSignedIn,
	SetUserSignedOut,
	SetUserIsDeleted,
	CheckingStatus,
}

interface IAction {
	type: ActionTypes;
	payload?: ICognitoUser;
}

interface IAuthState {
	isSignedIn: boolean;
	user?: ICognitoUser;
	isLoading: boolean;
	isDeleted: boolean;
}

function authReducer(state: IAuthState, action: IAction) {
	switch (action.type) {
		case ActionTypes.SetUserSignedIn:
			return {
				isSignedIn: true,
				isLoading: false,
				isDeleted: false,
				user: action.payload,
			};
		case ActionTypes.SetUserSignedOut:
			return {
				...state,
				isSignedIn: false,
				isLoading: false,
				user: undefined,
			};
		case ActionTypes.SetUserIsDeleted:
			return {
				...state,
				isDeleted: true,
			};
		case ActionTypes.CheckingStatus:
			return {
				...state,
				isLoading: true,
			};
		default:
			return state;
	}
}
const useUpdateAuthState = (dispatch: Dispatch<IAction>) => {
	const updateAuthState = async () => {
		dispatch({ type: ActionTypes.CheckingStatus });
		try {
			const user = (await cognitoClient.getCurrentUser()) as ICognitoUser;
			if (user) {
				dispatch({ type: ActionTypes.SetUserSignedIn, payload: user });
			} else {
				throw new Error('No user logged');
			}
		} catch (error) {
			log.debug('getCurrentUser error:', error);
			dispatch({ type: ActionTypes.SetUserSignedOut });
		}
	};

	useEffect(() => {
		(async () => {
			await updateAuthState();
		})();

		cognitoClient.addChangeListener(updateAuthState);

		return () => {
			cognitoClient.removeChangeListener(updateAuthState);
		};
	}, [cognitoClient]);
};

export const AuthContextProvider = ({ children }: PropsWithChildren<{}>) => {
	const [state, dispatch] = useReducer(authReducer, {
		isLoading: true,
		user: undefined,
		isSignedIn: false,
		isDeleted: false,
	});
	const { isRedirecting } = useAuthRedirect(state.isLoading, state.isSignedIn);

	useUpdateAuthState(dispatch);

	const signOut = async ({ isDeleted = false }: { isDeleted?: boolean }) => {
		if (isDeleted) {
			dispatch({ type: ActionTypes.SetUserIsDeleted });
		}
		return cognitoClient.signOut();
	};

	return (
		<AuthContext.Provider
			value={{
				isSignedIn: state.isSignedIn,
				user: state.user,
				signOut,
				isRedirecting,
				isLoading: state.isLoading,
				isDeleted: state.isDeleted,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
