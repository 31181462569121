import React from 'react';
import styled from '@emotion/styled';
import { FixedGridItem, Link, Responsive } from '@m2/blueprint-ui';
import { FooterSectionHeader } from './FooterSectionHeader';
import appStoreIcon from '../../../assets/app-store.svg?reference';
import playStoreIcon from '../../../assets/play-store.svg?reference';

export interface IFooterAppLinksProps {
	title: string;
	appStore?: string;
	playStore?: string;
}

const FooterAppLinksWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: -10px;
	max-width: 300px;
	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin-bottom: 40px;
	}
`;

const FooterAppLink = styled.div`
	margin-top: 10px;
	margin-right: 10px;

	// IE11 fix
	flex-grow: 1;

	&:last-of-type {
		margin-right: 0;
	}

	img {
		display: block;
		height: 42px;
		border: none;
	}
`;

export const FooterAppLinks = ({
	title,
	appStore,
	playStore,
}: IFooterAppLinksProps) => (
	<FixedGridItem>
		<FooterSectionHeader>{title}</FooterSectionHeader>
		<FooterAppLinksWrap>
			{appStore && (
				<FooterAppLink>
					<Link
						hideExternalIndicator
						href={appStore}
						target="_blank"
						rel="noopener"
					>
						<img alt="App Store" src={appStoreIcon} />
					</Link>
				</FooterAppLink>
			)}
			{playStore && (
				<FooterAppLink>
					<Link
						hideExternalIndicator
						href={playStore}
						target="_blank"
						rel="noopener"
					>
						<img alt="Google Play" src={playStoreIcon} />
					</Link>
				</FooterAppLink>
			)}
		</FooterAppLinksWrap>
	</FixedGridItem>
);
