import React from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { ChevronRightIcon, ITheme, themed } from '@m2/blueprint-ui';
import SignUpAccountBenefits from '../../../auth/sign-up/ui/SignUpAccountBenefits';
import { CustomPageId } from '../../../contentful/routes';

export const AccountMenuItemLoggedOut = () => (
	<>
		<Link href={`/${CustomPageId.SignIn}`} passHref>
			<StyledAccountMenuSubMenuItem>
				Sign in <ChevronRightIcon variation="brand" />
			</StyledAccountMenuSubMenuItem>
		</Link>
		<Link href={`/${CustomPageId.SignUp}`} passHref>
			<StyledAccountMenuSubMenuItem>
				Create account <ChevronRightIcon variation="brand" />
			</StyledAccountMenuSubMenuItem>
		</Link>
		<StyledAccountMenuAccountBenefits>
			<SignUpAccountBenefits />
		</StyledAccountMenuAccountBenefits>
	</>
);

export const StyledAccountMenuSubMenuItem = styled.a<{}, ITheme>`
	padding: 12px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: none;

	border-bottom: 1px solid transparent;
	-webkit-appearance: none;
	background-color: unset;
	outline: none;
	color: ${themed(({ color }) => color.text.default)};

	&:hover {
		text-decoration: none;
		color: ${themed(({ color }) => color.brand)};
	}

	&:last-child {
		border-bottom: none;
	}

	&:first-of-type {
		border-color: ${themed(({ color }) => color.border.default)};
	}

	&.no-border {
		border-bottom: 0;
	}
`;

const StyledAccountMenuAccountBenefits = styled.div<{}, ITheme>`
	pointer-events: none;
	padding: 0 16px;
	font-size: 14px;
	--paragraph-color: ${themed(({ color }) => color.text.default)};
	--paragraph-icon-color: ${themed(({ color }) => color.highlight)};
	--paragraph-margin: 8px;
`;
