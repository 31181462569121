import React, { ReactNode } from 'react';
import { MarkdownRenderContext } from '@m2/blueprint-ui';
import { renderers } from './renderers';

interface IMarkdownRenderContextProviderProps {
	children: ReactNode;
}

export const MarkdownRenderContextProvider = ({
	children,
}: IMarkdownRenderContextProviderProps) => (
	<MarkdownRenderContext.Provider value={renderers}>
		{children}
	</MarkdownRenderContext.Provider>
);
