import { getPublicConfig } from '../config';
import { isServerSide } from './isServerSide';

export function getBaseUrl() {
	const { baseUrl } = getPublicConfig();

	if (baseUrl) {
		return process.env.SITE_ROOT ?? baseUrl;
	}

	if (!isServerSide()) {
		return `${window.location.protocol}//${window.location.host}`;
	}

	return undefined;
}
