import { AxiosRequestConfig } from 'axios';
import { ApiClient } from '@m2/blueprint-utils';
import { AbstractService } from './AbstractService';
import { ISkydiveShuttleVehiclesResponse } from '../types/ISkydiveShuttleVehiclesResponse';

export class ShuttleVehiclesService extends AbstractService {
	/**
	 * Path of the resource to be used by the service.
	 */
	private readonly baseUriPath: string;

	public static readonly URI_PATH = '/vehicles';

	constructor(client: ApiClient, uriPath: string) {
		super(client);
		this.baseUriPath = uriPath;
	}

	public getShuttleVehicles(
		requestConfig: AxiosRequestConfig = {
			method: 'GET',
		},
	): Promise<ISkydiveShuttleVehiclesResponse> {
		const uri = this.baseUriPath + ShuttleVehiclesService.URI_PATH;

		return this.client.request(uri, requestConfig);
	}
}
