import React from 'react';
import { observer } from 'mobx-react';

import {
	CancelIcon,
	ITheme,
	Responsive,
	SearchIcon,
	themed,
} from '@m2/blueprint-ui';
import styled from '@emotion/styled';
import { UnifiedSearchMenuToggleViewModel } from './UnifiedSearchMenuToggleViewModel';
import { NAVIGATION_HIDE_SEARCH_LABEL_BREAKPOINT } from '../../components/common/header/components';

interface IUnifiedSearchMenuToggleProps {
	viewModel: UnifiedSearchMenuToggleViewModel;
	onClick: () => void;
}

export const UnifiedSearchMenuToggle = observer(
	({ viewModel, onClick }: IUnifiedSearchMenuToggleProps) => {
		const buttonText = viewModel.isUnifiedSearchActive ? 'Close' : 'Search';
		return (
			<StyledButton onClick={onClick} type="button" title={buttonText}>
				<StyledText isTransparent={viewModel.isTransparent}>
					{buttonText}
				</StyledText>
				{viewModel.isUnifiedSearchActive ? (
					<StyledCancelIcon
						variation={viewModel.isTransparent ? 'inverted' : 'brand'}
					/>
				) : (
					<StyledSearchIcon
						variation={viewModel.isTransparent ? 'inverted' : 'brand'}
					/>
				)}
			</StyledButton>
		);
	},
);

const StyledText = styled.p<{ isTransparent: boolean }, ITheme>`
	display: block;
	color: white;
	margin: 0 15px;
	color: ${themed(({ color }, { isTransparent }) =>
		isTransparent ? color.text.inverted : color.text.default,
	)};

	@media (${NAVIGATION_HIDE_SEARCH_LABEL_BREAKPOINT}) {
		margin-right: 25px;
		display: none;
	}
`;

const StyledButton = styled.button`
	display: flex;
	align-items: center;
	margin: 0 0 0 auto;
	padding: 0;
	cursor: pointer;
	background-color: transparent;
	border: none;
	font-size: inherit;
	@media (${NAVIGATION_HIDE_SEARCH_LABEL_BREAKPOINT}) {
		margin-left: auto;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		margin-right: 0;
	}
`;

const StyledCancelIcon = styled(CancelIcon)`
	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		width: 32px;
		height: 32px;
		margin-bottom: 8px;
	}
`;

const StyledSearchIcon = styled(SearchIcon)`
	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		width: 32px;
		height: 32px;
		margin-bottom: 6px;
	}
`;
