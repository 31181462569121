import log from 'loglevel';
import { InferType, mixed, object, string } from 'yup';
import { removeEmptyValues } from './removeEmptyValues';
import { Environment } from './types/Environment';

export const publicConfigSchema = object({
	version: string(),
	environment: mixed().oneOf(Object.values(Environment)).required(),
	logLevel: string().required(),
	etaspotBaseUri: string().required(),
	skydiveBaseUri: string().required(),
	gatewayBaseUri: string().required(),
	webbankBaseUri: string().required(),
	locustLabsVenueId: string().required(),
	locustLabsAccountId: string().required(),
	baseUrl: string().required(),
	legacyTrackingId: string(),
	trackingId: string(),
	googleTagManagerId: string(),
	chatBotUrl: string(),
	algoliaAppId: string(),
	algoliaReadOnlyApiKey: string(),
	algoliaContentIndex: string(),
	awsRegion: string(),
	awsUserPoolId: string(),
	awsUserPoolWebClientId: string(),
	mapBoxParkingPublicAccessToken: string(),
}).required();

export type PublicConfig = InferType<typeof publicConfigSchema>;

let config: PublicConfig | undefined;

export function getPublicConfig() {
	if (config) {
		return config;
	}

	const environment = (process.env.ENVIRONMENT ||
		process.env.NEXT_PUBLIC_ENVIRONMENT) as Environment;

	const defaults: Partial<PublicConfig> = {
		version: process.env.NEXT_PUBLIC_VERSION,
		environment,
		logLevel: 'warn',
		...getPublicConfigForEnvironment(environment),
	};

	const custom = getCustomPublicConfig();

	config = publicConfigSchema.validateSync({
		...defaults,
		...custom,
	});

	return config;
}

function getCustomPublicConfig() {
	const customConfig: Partial<PublicConfig> = {
		logLevel: process.env.NEXT_PUBLIC_LOG_LEVEL,
		skydiveBaseUri: process.env.NEXT_PUBLIC_SKYDIVE_BASE_URI,
		gatewayBaseUri: process.env.NEXT_PUBLIC_GATEWAY_BASE_URI,
	};

	return removeEmptyValues(customConfig);
}

export function getPublicConfigForEnvironment(environment?: Environment) {
	if (!environment) {
		return {};
	}

	switch (environment) {
		case Environment.Development:
			return {
				logLevel: 'debug',
				etaspotBaseUri: 'https://dfwtlpublic.etaspot.net/service.php',
				skydiveBaseUri: 'https://test.api.dfwairport.mobi',
				gatewayBaseUri: 'https://test.gateway.dfwairport.mobi',
				webbankBaseUri: 'https://demo-gateway.api.advam.com/stored-token',
				baseUrl: 'http://localhost:3000',
				legacyTrackingId: 'UA-185299466-1',
				trackingId: 'G-ZQPSNW26RL',
				googleTagManagerId: 'GTM-WGN4ZBQ',
				chatBotUrl: 'https://stage.airport.ai/',
				locustLabsVenueId: 'dfw',
				locustLabsAccountId: 'A1GKNNFXZEQW1J',
				algoliaAppId: 'AMEXRL1U2X',
				algoliaReadOnlyApiKey: 'acf8fe40a7cdaa899a3d76c76460d283',
				algoliaContentIndex: 'contentful_test',
				awsRegion: 'us-east-1',
				awsUserPoolId: 'us-east-1_ssTQHovn5',
				awsUserPoolWebClientId: 'q9i7b1joucf1t9dfck5511b41',
				mapBoxParkingPublicAccessToken:
					'pk.eyJ1IjoibW92ZWRmdyIsImEiOiJjbGlsbjJpczEwYWFqM21wamtpbWVzc3BpIn0.tNiURmq_aNuBhmsCeok-9A',
			};

		case Environment.Test:
			return {
				logLevel: 'info',
				etaspotBaseUri: 'https://dfwtlpublic.etaspot.net/service.php',
				skydiveBaseUri: 'https://test.api.dfwairport.mobi',
				gatewayBaseUri: 'https://test.gateway.dfwairport.mobi',
				webbankBaseUri: 'https://demo-gateway.api.advam.com/stored-token',
				baseUrl: 'https://test.web.dfwairport.mobi',
				legacyTrackingId: 'UA-185299466-2',
				trackingId: 'G-8K0C3SR4PZ',
				googleTagManagerId: 'GTM-NB2CHJ3',
				chatBotUrl: 'https://stage.airport.ai/',
				locustLabsVenueId: 'dfw',
				locustLabsAccountId: 'A1GKNNFXZEQW1J',
				algoliaAppId: 'AMEXRL1U2X',
				algoliaReadOnlyApiKey: 'acf8fe40a7cdaa899a3d76c76460d283',
				algoliaContentIndex: 'contentful_test',
				awsRegion: 'us-east-1',
				awsUserPoolId: 'us-east-1_x2F9E0o3S',
				awsUserPoolWebClientId: '772lu002gd4s59jbf3lds9k45r',
				mapBoxParkingPublicAccessToken:
					'pk.eyJ1IjoibW92ZWRmdyIsImEiOiJjbGlsbjJpczEwYWFqM21wamtpbWVzc3BpIn0.tNiURmq_aNuBhmsCeok-9A',
			};

		case Environment.Acceptance:
			return {
				logLevel: 'info',
				etaspotBaseUri: 'https://dfwtlpublic.etaspot.net/service.php',
				skydiveBaseUri: 'https://acc.api.dfwairport.mobi',
				gatewayBaseUri: 'https://acc.gateway.dfwairport.mobi',
				baseUrl: 'https://acc.web.dfwairport.mobi',
				webbankBaseUri: 'https://gateway.api.advam.com/stored-token',
				legacyTrackingId: 'UA-185299466-3',
				trackingId: 'G-7E0DZ2BBLB',
				googleTagManagerId: 'GTM-K4PDWS9',
				chatBotUrl: 'https://external.airport.ai/',
				locustLabsVenueId: 'dfw',
				locustLabsAccountId: 'A1GKNNFXZEQW1J',
				algoliaAppId: 'AMEXRL1U2X',
				algoliaReadOnlyApiKey: 'acf8fe40a7cdaa899a3d76c76460d283',
				algoliaContentIndex: 'contentful_acceptance',
				awsRegion: 'us-east-1',
				awsUserPoolId: 'us-east-1_UjzcFfR11',
				awsUserPoolWebClientId: '4jiv2pnitls9d8m7mhnr3s63mb',
				mapBoxParkingPublicAccessToken:
					'pk.eyJ1IjoibW92ZWRmdyIsImEiOiJjbGlsbjJpczEwYWFqM21wamtpbWVzc3BpIn0.tNiURmq_aNuBhmsCeok-9A',
			};

		case Environment.Ghost:
			return {
				etaspotBaseUri: 'https://dfwtlpublic.etaspot.net/service.php',
				skydiveBaseUri: 'https://api.dfwairport.mobi',
				gatewayBaseUri: 'https://gateway.dfwairport.mobi',
				webbankBaseUri: 'https://gateway.api.advam.com/stored-token',
				baseUrl: 'https://ghost.web.dfwairport.mobi',
				legacyTrackingId: 'UA-185299466-3',
				trackingId: 'G-7E0DZ2BBLB',
				googleTagManagerId: 'GTM-K4PDWS9',
				chatBotUrl: 'https://external.airport.ai/',
				locustLabsVenueId: 'dfw',
				locustLabsAccountId: 'A1GKNNFXZEQW1J',
				algoliaAppId: 'AMEXRL1U2X',
				algoliaReadOnlyApiKey: 'acf8fe40a7cdaa899a3d76c76460d283',
				algoliaContentIndex: 'contentful_master',
				awsRegion: 'us-east-1',
				awsUserPoolId: 'us-east-1_i7vBM5KZx',
				awsUserPoolWebClientId: '4g81nn6jaq6ro2pgg18n04atjg',
				mapBoxParkingPublicAccessToken:
					'pk.eyJ1IjoibW92ZWRmdyIsImEiOiJjbGlsbjJpczEwYWFqM21wamtpbWVzc3BpIn0.tNiURmq_aNuBhmsCeok-9A',
			};

		case Environment.Production:
			return {
				etaspotBaseUri: 'https://dfwtlpublic.etaspot.net/service.php',
				skydiveBaseUri: 'https://api.dfwairport.mobi',
				gatewayBaseUri: 'https://gateway.dfwairport.mobi',
				webbankBaseUri: 'https://gateway.api.advam.com/stored-token',
				baseUrl: 'https://www.dfwairport.com',
				legacyTrackingId: 'UA-625171-1',
				trackingId: 'G-JY80H9YV46',
				googleTagManagerId: 'GTM-P347LN',
				chatBotUrl: 'https://external.airport.ai/',
				locustLabsVenueId: 'dfw',
				locustLabsAccountId: 'A1GKNNFXZEQW1J',
				algoliaAppId: 'AMEXRL1U2X',
				algoliaReadOnlyApiKey: 'acf8fe40a7cdaa899a3d76c76460d283',
				algoliaContentIndex: 'contentful_master',
				awsRegion: 'us-east-1',
				awsUserPoolId: 'us-east-1_i7vBM5KZx',
				awsUserPoolWebClientId: '4g81nn6jaq6ro2pgg18n04atjg',
				mapBoxParkingPublicAccessToken:
					'pk.eyJ1IjoibW92ZWRmdyIsImEiOiJjbGlsbjJpczEwYWFqM21wamtpbWVzc3BpIn0.tNiURmq_aNuBhmsCeok-9A',
			};

		default:
			log.warn('No default public config for unknown environment', environment);
			return {};
	}
}
