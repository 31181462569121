import React from 'react';
import Head from 'next/head';
import { observer } from 'mobx-react';
import { useCookieConsentStore } from '../cookie-consent/useCookieConsentStore';
import { getPublicConfig } from '../config';

export const GoogleTagManager = observer(() => {
	const { cookieConsentStore } = useCookieConsentStore();
	const { googleTagManagerId } = getPublicConfig();

	if (!googleTagManagerId || !cookieConsentStore.hasConsent) {
		return null;
	}

	return (
		<Head>
			<script
				async
				src={`https://www.googletagmanager.com/gtm.js?id=${googleTagManagerId}`}
			/>
		</Head>
	);
});
