import React from 'react';
import { RouterLinkComponentType, IRouterLinkProps } from '@m2/blueprint-ui';
import NextLink from 'next/link';
import { useCustomNextLinkContext } from './CustomNextLinkContext';

export const RouterLink: RouterLinkComponentType = ({
	href,
	children,
	...rest
}: IRouterLinkProps) => {
	const customNextLinkProps = useCustomNextLinkContext();

	return (
		<NextLink href={href} {...customNextLinkProps}>
			<a href={href} {...rest}>
				{children}
			</a>
		</NextLink>
	);
};
