import React from 'react';
import styled from '@emotion/styled';
import {
	CancelIcon,
	ChevronRightIcon,
	ITheme,
	MenuIcon,
	Responsive,
	themed,
} from '@m2/blueprint-ui';
import Link from 'next/link';
import { ISiteSectionEntry } from '@m2/blueprint-contentful';
import dfwLogoBrand from '../../../../assets/dfw-logo-brand.svg?reference';
import dfwLogoInverted from '../../../../assets/dfw-logo-inverted.svg?reference';
import { IStyledProps } from '../Header';
import { useCommonData } from '../../../../common-data/useCommonData';
import { Color } from '../../../../styles/Color';
import { DropDownMenuMobile } from './dropdown-menu/mobile/DropDownMenuMobile';
import { MobileMenuOverlay } from './dropdown-menu/mobile/MobileMenuOverlay';

export interface ITopNavigationMobileProps extends IStyledProps {
	mobileNavVisibility: boolean;
	setMobileNavVisibility: (value: boolean) => void;
	isTransparent: boolean;
}

export const TopNavigationMobile = ({
	mobileNavVisibility,
	setMobileNavVisibility,
	isTransparent,
}: ITopNavigationMobileProps) => {
	const { siteSections, pathToPageIdMap } = useCommonData();

	const handleClickLink = () => {
		setMobileNavVisibility(false);
	};

	return (
		<StyledMobileMenuWrapper>
			{!mobileNavVisibility && (
				<>
					<StyledMobileMenuIcon
						type="button"
						aria-label="Open Menu"
						onClick={() => setMobileNavVisibility(true)}
					>
						<MenuIcon variation={isTransparent ? 'inverted' : 'brand'} />
					</StyledMobileMenuIcon>
					<Link href="/">
						<StyledImageWrapper>
							{isTransparent ? (
								<img src={dfwLogoInverted} alt="" />
							) : (
								<img src={dfwLogoBrand} alt="" />
							)}
						</StyledImageWrapper>
					</Link>
				</>
			)}

			{mobileNavVisibility && (
				<>
					<StyledMobileMenuIcon
						type="button"
						aria-label="Close Menu"
						onClick={() => setMobileNavVisibility(false)}
					>
						<CancelIcon variation="brand" />
					</StyledMobileMenuIcon>
					<Link href="/">
						<StyledImageWrapper>
							<img src={dfwLogoBrand} alt="" />
						</StyledImageWrapper>
					</Link>
				</>
			)}

			<StyledMobileMenuBackground mobileNavVisibility={mobileNavVisibility}>
				{mobileNavVisibility &&
					siteSections.map((siteSection: ISiteSectionEntry) => (
						<StyledMobileSiteSectionWrapper key={siteSection.id}>
							<Link passHref href={`/${siteSection.fields.slug ?? ''}`}>
								<StyledMobileSiteSectionItem onClick={handleClickLink}>
									{siteSection.fields.title}
									<ChevronRightIcon variation="brand" />
								</StyledMobileSiteSectionItem>
							</Link>
							<StyledDropDownMenu>
								<DropDownMenuMobile
									isTransparent={isTransparent}
									pathToPageIdMap={pathToPageIdMap}
									menuItems={siteSection.fields.menuItems}
									onClickLink={handleClickLink}
								/>
							</StyledDropDownMenu>
						</StyledMobileSiteSectionWrapper>
					))}
			</StyledMobileMenuBackground>
		</StyledMobileMenuWrapper>
	);
};

export const StyledImageWrapper = styled.div<{}, ITheme>`
	width: 100%;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		cursor: pointer;
		z-index: 2200;
		text-align: center;

		img {
			width: 129px;
			height: 64px;
		}
		img {
			width: 81px;
			height: 40px;
		}
	}
`;

const StyledDropDownMenu = styled.div`
	display: none;
	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		display: block;
	}
`;

export const StyledMobileMenuWrapper = styled.div`
	display: none;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		display: flex;
		width: 100%;
		z-index: 2100;
		padding: 0;
	}
`;

export const StyledMobileMenuIcon = styled.button<IStyledProps, ITheme>`
	z-index: 2200;
	width: 32px;
	height: 32px;
	padding-left: 0px;
	padding-right: 62px;
	cursor: pointer;

	svg {
		width: 32px;
		height: 32px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		background-color: transparent;
		border: 0;
	}
`;

export const StyledMobileMenuBackground = styled(MobileMenuOverlay)<
	{ mobileNavVisibility: boolean },
	ITheme
>`
	z-index: 2000;
	transition: all 0.15s ease-in-out;
	width: ${({ mobileNavVisibility }) => (mobileNavVisibility ? '100%' : 0)};
	opacity: ${({ mobileNavVisibility }) => (mobileNavVisibility ? 1 : 0)};
	height: ${({ mobileNavVisibility }) => (mobileNavVisibility ? '100vh' : 0)};
	overflow: ${({ mobileNavVisibility }) =>
		mobileNavVisibility ? 'auto' : 'hidden'};
`;

export const StyledMobileSiteSectionWrapper = styled.div<{}, ITheme>`
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
`;

export const StyledMobileSiteSectionItem = styled.a<{}, ITheme>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 35px;
	border: 0;
	border-bottom: 1px solid ${Color.PrimaryLightGray};
	background-color: transparent;
	font-family: inherit;
	font-weight: bold;
	font-size: 18px;
	color: ${Color.PrimaryDarkGray100};
	margin-bottom: 24px;

	&:hover {
		color: ${themed(({ color }) => color.brand)};
		cursor: pointer;
		text-decoration: none;
	}
`;

export const StyledMobileMenuItem = styled.div<{}, ITheme>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border: 0;
	font-family: inherit;
	font-size: 16px;
	background-color: transparent;
	color: ${Color.PrimaryDarkGray100};
	margin-bottom: 24px;

	&:hover {
		color: ${themed(({ color }) => color.brand)};
		cursor: pointer;
		text-decoration: none;
	}
`;

export const StyledMenuIconText = styled.div`
	display: flex;
	align-items: center;

	img {
		margin-right: 16px;
		width: 24px;
		height: 24px;
	}
`;
