export type SetLocalStorageItem = (key: string, value: string) => void;
export type GetLocalStorageItem = (key: string) => string | null;

export function setLocalStorageItem(key: string, value: string) {
	if (window) {
		window.localStorage.setItem(key, value);
	}
}

export function getLocalStorageItem(key: string): string | null {
	if (!window) {
		return null;
	}

	return window.localStorage.getItem(key);
}
