import { IEnrichedFlight } from '../../../shared/domain/types/IEnrichedFlight';
import { SearchFilters } from '../types/SearchFilters';

export function flightsSearchFilter(
	flight: IEnrichedFlight,
	searchFilter?: SearchFilters,
) {
	if (!searchFilter) return true;

	if (searchFilter.airlineIata) {
		return (
			flight.iataMainPrefix.toLocaleLowerCase() ===
			searchFilter.airlineIata.toLocaleLowerCase()
		);
	}

	if (searchFilter.airlineName) {
		return (
			flight.airlineDetails?.airlineName.toLocaleLowerCase() ===
			searchFilter.airlineName.toLocaleLowerCase()
		);
	}

	if (searchFilter.airportIata) {
		return flight.arrival
			? flight.originAirport.toLocaleLowerCase() ===
					searchFilter.airportIata.toLocaleLowerCase()
			: flight.destinationAirport.toLocaleLowerCase() ===
					searchFilter.airportIata.toLocaleLowerCase();
	}

	if (searchFilter.airportName) {
		return flight.arrival
			? flight.originAirportDetails?.airportName.toLocaleLowerCase() ===
					searchFilter.airportName.toLocaleLowerCase()
			: flight.destinationAirportDetails?.airportName.toLocaleLowerCase() ===
					searchFilter.airportName.toLocaleLowerCase();
	}

	if (searchFilter.airportCity) {
		return flight.arrival
			? flight.originAirportDetails?.cityName.toLocaleLowerCase() ===
					searchFilter.airportCity.toLocaleLowerCase()
			: flight.destinationAirportDetails?.cityName.toLocaleLowerCase() ===
					searchFilter.airportCity.toLocaleLowerCase();
	}

	if (searchFilter.mainFlightNumber) {
		return (
			flight.mainFlightNumber.toLocaleLowerCase() ===
			searchFilter.mainFlightNumber.toLocaleLowerCase()
		);
	}

	if (searchFilter.all) {
		const lowerCaseSearchFilter = searchFilter.all.toLocaleLowerCase();
		if (
			flight.mainFlightNumber
				.toLocaleLowerCase()
				.includes(lowerCaseSearchFilter) ||
			flight.originAirportDetails?.cityName
				.toLocaleLowerCase()
				.includes(lowerCaseSearchFilter) ||
			flight.destinationAirportDetails?.cityName
				.toLocaleLowerCase()
				.includes(lowerCaseSearchFilter) ||
			flight.originAirportDetails?.airportName
				.toLocaleLowerCase()
				.includes(lowerCaseSearchFilter) ||
			flight.destinationAirportDetails?.airportName
				.toLocaleLowerCase()
				.includes(lowerCaseSearchFilter) ||
			flight.originAirport
				.toLocaleLowerCase()
				.includes(lowerCaseSearchFilter) ||
			flight.destinationAirport
				.toLocaleLowerCase()
				.includes(lowerCaseSearchFilter) ||
			flight.airlineDetails?.airlineName
				.toLocaleLowerCase()
				.includes(lowerCaseSearchFilter) ||
			flight.iataMainPrefix.toLocaleLowerCase().includes(lowerCaseSearchFilter)
		)
			return true;
	}
	return false;
}
