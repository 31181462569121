import React from 'react';
import { IFooterLinkSections } from './Footer';
import { FooterLinkSection } from './FooterLinkSection';

export const FooterLinkSections = ({
	linkSections,
}: {
	linkSections: IFooterLinkSections;
}) => (
	<>
		{linkSections.map((linkSection) => (
			<FooterLinkSection {...linkSection} />
		))}
	</>
);
