import { AxiosRequestConfig } from 'axios';
import { ApiClient } from '@m2/blueprint-utils';
import { AbstractService } from './AbstractService';
import { IShuttleStopInfoResponse } from '../types/ISkydiveShuttleStopInfoResponse';
import { IShuttleStopsResponse } from '../types/ISkydiveShuttleStopsResponse';

export class ShuttleStopsService extends AbstractService {
	/**
	 * Path of the resource to be used by the service.
	 */
	private readonly baseUriPath: string;

	public static readonly URI_PATH = '/stops';

	constructor(client: ApiClient, baseUriPath: string) {
		super(client);
		this.baseUriPath = baseUriPath;
	}

	public getShuttleStops(
		requestConfig: AxiosRequestConfig = {
			method: 'GET',
		},
	): Promise<IShuttleStopsResponse> {
		const uri = this.baseUriPath + ShuttleStopsService.URI_PATH;

		return this.client.request(uri, requestConfig);
	}

	public getShuttleStopInfo(
		id: string,
		requestConfig: AxiosRequestConfig = { method: 'GET' },
	): Promise<IShuttleStopInfoResponse> {
		const uri = `${this.baseUriPath + ShuttleStopsService.URI_PATH}/${id}`;

		return this.client.request(uri, requestConfig);
	}
}
