import React from 'react';
import { css, Global } from '@emotion/core';

export const Lists = () => {
	const styles = css`
		ul {
			li {
				list-style: disc;
			}
		}
	`;

	return <Global styles={styles} />;
};
