export const AIRLINE_TYPE_ID = 'airline';
export const AIRPORT_TYPE_ID = 'airport';
export const GENERIC_CONTENT_PAGE_TYPE_ID = 'page';
export const EXTERNAL_LINK_TYPE_ID = 'external-link';
export const TEXT_SECTION_TYPE_ID = 'text-section';
export const CAROUSEL_SECTION_TYPE_ID = 'carousel-section';
export const CAROUSEL_ITEM_TYPE_ID = 'carousel-item';
export const ACCORDION_SECTION_TYPE_ID = 'accordion-section';
export const ACCORDION_ITEM_TYPE_ID = 'accordion-item';
export const CARD_SECTION_TYPE_ID = 'card-section';
export const CARD_ITEM_TYPE_ID = 'card-item';
export const CONTACT_ITEM_TYPE_ID = 'contact-item';
export const CONTACT_SECTION_TYPE_ID = 'contact-section';
export const QUICK_LINKS_SECTION_TYPE_ID = 'quick-links-section';
export const CALL_TO_ACTION_SECTION_TYPE_ID = 'call-to-action-section';
export const TAB_SECTION_TYPE_ID = 'tab-section';
export const TAB_ITEM_TYPE_ID = 'tab-item';
export const EMAIL_LINK_TYPE_ID = 'email-link';
export const PHONE_LINK_TYPE_ID = 'phone-link';
export const ASSET_LINK_TYPE_ID = 'asset-link';
export const PHOTO_GALLERY_SECTION_TYPE_ID = 'photo-gallery-section';
export const PHOTO_GALLERY_ITEM_TYPE_ID = 'photo-gallery-item';
export const HIGHLIGHT_SECTION_TYPE_ID = 'highlight-section';
export const NEWS_SECTION_TYPE_ID = 'news-section';
export const NEWS_ITEM_TYPE_ID = 'news-item';
export const IFRAME_SECTION_TYPE_ID = 'iframe-section';
export const COLUMN_SECTION_TYPE_ID = 'column-section';
export const NEWS_ALERT_TYPE_ID = 'news-alert';
export const FOOTER_TYPE_ID = 'footer';
export const CUSTOM_PAGES_CONTAINER_TYPE_ID = 'custom-pages';
export const REDIRECT_PATH_TYPE_ID = 'redirect-path';
export const PROMOTIONAL_MESSAGE = 'promotional-message';
export const PARKING_WIDGET = 'parking-widget';
export const SHUTTLE_TRACKING_MESSAGES_ID = 'shuttle-tracker-message';
export const PARKING_AVAILABILITY_SECTION_TYPE_ID =
	'parking-availability-section';
export const POPULAR_SEARCHES = 'popularSearches';
