import { useContext, useMemo } from 'react';
import { TOptions } from 'i18next';
import { I18nContext } from './I18nContext';
import { TranslationKey } from './TranslationKey';

export interface ITranslationFunction {
	(key: TranslationKey, options?: TOptions): string;
}

export const useTranslation = () => {
	const i18n = useContext(I18nContext);

	if (!i18n) {
		throw new Error('No I18n instance initialized');
	}

	return useMemo(() => {
		const t = i18n.t.bind(i18n) as ITranslationFunction;
		return { i18n, t };
	}, [i18n]);
};
